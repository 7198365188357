<template>
	<div id="faq-write">
		<div class="row">
			<div class="col-lg-8">
				<div class="card">
					<div class="card-body">
						<div @click="clickBack">
							<svg width="19" height="33" viewBox="0 0 19 33" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
							<rect width="19" height="33" fill="url(#pattern0)"/>
							<defs>
							<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
							<use xlink:href="#image0_5035_2502" transform="scale(0.0526316 0.030303)"/>
							</pattern>
							<image id="image0_5035_2502" width="19" height="33" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAhCAYAAAA74pBqAAAAyUlEQVRIDe3UsQrCMBAG4D54BwcXJxWc4+ZgB4eC4BtkEweH4gs4dVEHh/KXIJFoSe7+2kl6S3Ih+bgcIRkGjGxACyPGd/MPe1bbEsvNCbegG72uWdsdpjOD7fERUODfWQxyKlVZCqIwCVJjGkiFaSERY6AkxkIJ7ImqMMjnJc53t00X8afRXGHXBvlirwbjmCvGg6sDKkWFaYwEZYwAdZgS1GMKkMMEkMcCcFJcXPaOfpg/3vjJa/wN+7S4z/HrbCcdK+u0RFxoAURzl7tvYD0wAAAAAElFTkSuQmCC"/>
							</defs>
							</svg>
						</div>

						<div class="content-area">
							<h2>신규 FAQ 등록하기</h2>

							<div class="top-title">
								<span>대상</span>
								<select class="form-control" v-model="writeData.category">
											<option>공통</option>
											<option>예술강사</option>
											<option>운영학교</option>
										</select>
							</div>
							<div class="form-group">
								<form class="mt-4">
									<input type="text" class="form-control" v-model="writeData.subject">
									<textarea id="content" class="form-control content-box" v-model="writeData.content">
									</textarea>
								</form>
							</div>

							<div class="button-group">
								<button type="button" class="btn waves-effect waves-light btn-secondary" @click="clickCancel">취소하기</button>
								<button type="button" class="btn waves-effect waves-light btn-primary" @click="clickSave">등록하기</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="card">
					<div class="card-body">
						<div class="iphone">
							<phone-view :data="writeData" mode="faq" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PhoneView from '../../../components/phoneview'

	export default {
		name: 'FaqWrite',
		data() {
			return {
				isViewMode: true,
				category: '',
				writeData: {category:'공통', subject:'', content:''},
				text: '',
				list: [],
				curSeq: -1,
				categoryOn: false,
				parentCategory: '',
			}
		},
		mounted() {
			this.parentCategory = this.$route.params.category;
		},
		methods: {
			clickBack: function () {
				this.$router.push({
					name: 'FaqHome',
					params: {
						category: this.category,
					}
				})
			},
			clickDelete: function () {
				
			},
			clickModify: function () {
				this.writeData = { ...this.data };
				this.viewData = this.writeData;
				this.isViewMode = false; //Modify Mode.
			},
			clickCancel: function () {
				this.$router.push({
					name: 'FaqHome',
					params: {
						category: this.category,
					}
				})
			},
			clickFaqItem: function (idx) {
				//this.list[idx].active = !this.list[idx].active;
				if(this.curSeq != this.list[idx].seq) {
				this.curSeq = this.list[idx].seq;
				} else {
					this.curSeq = -1;
				}

			},
			clickSave: function () {
				let params = {
					seq: this.writeData.seq,
					subject: this.writeData.subject,
					content: this.writeData.content,
					banner: '',
					fix: '9',
					push: '',
					menu: 'faq',
					category: this.writeData.category,
				};
				
				this.$http.post('https://asai.sacesapp.com/v1/Post/NewPost', params)
				.then((res) => {
					if(res.data.success === true) {
						this.$event.$emit('toast','FAQ가 등록되었습니다');
						this.$router.push({
							name: 'FaqHome',
							params: {
								category: this.parentCategory,
							}
						})
					}
				})
			}
		},
		components: {
			PhoneView,
		}
	}
</script>

<style scoped>
	.top-title {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.top-title>span {
		margin-top: 8px;
	}

	.top-title>select {
		width: 124px;
		height: 41px;
	}

	#faq-write h2 {
		margin-top:12px;
		font-size: 24px;
	}



	.content-area {
		margin-left: 27px;
	}

	.content-box {
		height: calc(100vh - 300px);
	}

	#faq-write textarea {
		margin-top: 16px;
		height: calc(100vh - 400px);
	}

.iphone {
	background-image:url('../../../assets/iphone.png');
	background-repeat: no-repeat;
	background-size: cover;
	height: 847px;
	width: 430px;
	padding: 70px 30px 50px 30px;
	margin:0 auto;
}

	.button-group {
		display: flex;
		justify-content: right;
	}

	.button-group>button {
		margin-left: 20px;
	}

	.button-group>button:first {
		margin-left: 0px;
	}


.content-box {
	height: calc(100vh - 400px);
	overflow: auto;
}

.iphone ul {
		list-style: none;
		padding: 0px 10px;
		margin-top:20px;
		margin-left:5px;
		margin-right:5px;
	}

	.iphone ul li {
		padding: 15px 16px;
		background-color: #e4e6e8;
		border-radius: 10px;
		text-align: left;
		min-height: 60px;
		margin-bottom: 20px;
	}

	.iphone ul li .title {
		display: flex;
		height: 36px;
		justify-content: space-between;
		align-items: center;
	}

	.iphone .left {
		transform: translate(0%,0%)
	}

	.iphone .right {
		padding-top:0px;
		margin-top:-7px;
	}

	.iphone ul li h1 {
		font-size: 16px;
		font-weight: 500;
		margin:0;
		margin-right: 7px;
	}

	.iphone ul li h3 {
		font-size: 11px;
		font-weight: 400;
		padding:0;
		margin:0;
		margin-top: 5px;
	}

	.iphone .content {
		margin-top: 16px;
	}

	.iphone p {
		word-break: keep-all;
		font-size: 15px;
		font-weight: 400;
	}

	.iphone .margin-down {
		margin-top: 7px;
	}

	.iphone .margin-up {
		margin-top: -3px;
	}
</style>