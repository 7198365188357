<template>
	<div id="noticehomeview">
		<div class="row">
			<div class="col-lg-8">
				<div class="card">
					<div class="card-body">
						<div class="main-box">
							<div @click="clickBack">
								<svg width="19" height="33" viewBox="0 0 19 33" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								<rect width="19" height="33" fill="url(#pattern0)"/>
								<defs>
								<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
								<use xlink:href="#image0_5035_2502" transform="scale(0.0526316 0.030303)"/>
								</pattern>
								<image id="image0_5035_2502" width="19" height="33" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAhCAYAAAA74pBqAAAAyUlEQVRIDe3UsQrCMBAG4D54BwcXJxWc4+ZgB4eC4BtkEweH4gs4dVEHh/KXIJFoSe7+2kl6S3Ih+bgcIRkGjGxACyPGd/MPe1bbEsvNCbegG72uWdsdpjOD7fERUODfWQxyKlVZCqIwCVJjGkiFaSERY6AkxkIJ7ImqMMjnJc53t00X8afRXGHXBvlirwbjmCvGg6sDKkWFaYwEZYwAdZgS1GMKkMMEkMcCcFJcXPaOfpg/3vjJa/wN+7S4z/HrbCcdK+u0RFxoAURzl7tvYD0wAAAAAElFTkSuQmCC"/>
								</defs>
								</svg>
							</div>

							<content-view v-if="isViewMode" title="서식자료 열람하기" :subject="data.subject" :content="data.content" :category="data.category" />
							
							<div v-if="isViewMode" class="button-group">
								<button type="button" class="btn waves-effect waves-light btn-secondary" @click="clickDelete">삭제하기</button>
								<button type="button" class="btn waves-effect waves-light btn-primary" @click="clickModify">수정하기</button>
							</div>

							<div v-else class="content-area">
								<h2>서식자료 수정하기</h2>

								<div class="option-box">
									<span style="margin-top:8px;">대상</span>
									<select class="form-control" v-model="data.category">
										<option>공통</option>
										<option>예술강사</option>
										<option>운영학교</option>
										<option>비회원</option>
									</select>
								</div>
								
								<div class="form-group">
									<form class="mt-4">
										<input type="text" style="margin-bottom:15px;" class="form-control" v-model="data.subject">
										<textarea id="content" class="form-control" v-model="data.content">
										</textarea>
									</form>
								</div>

								<div class="button-group">
									<button type="button" class="btn waves-effect waves-light btn-secondary" @click="clickCancel">취소하기</button>
									<button type="button" class="btn waves-effect waves-light btn-primary" @click="clickSave">등록하기</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-4">
				<div class="card">
					<div class="card-body phone-box">
						<div class="iphone">
							<phone-view :data="viewData" mode="notice"></phone-view>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="delete-confirm-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="danger-header-modalLabel" aria-hidden="false">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-danger">
										<h4 class="modal-title" id="danger-header-modalLabel">삭제하기</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
										<p>계속하시겠습니까?</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal">취소하기</button>
										<button type="button" class="btn btn-danger" @click="deleteSubmit">삭제하기</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->

		<!-- Primary Header Modal -->
		<div id="ok-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="primary-header-modalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-primary">
										<h4 class="modal-title" id="primary-header-modalLabel">알림
										</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
									<p>서식이 수정되었습니다</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal">Close</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->

		<div aria-live="polite" aria-atomic="true" style="position: relative; min-height: 200px;">
      <!-- Position it -->
      <div style="position: absolute; top: 0; right: 0; z-index: 50000;">
        <div class="toast" role="alert" data-autohide="false" aria-live="assertive" aria-atomic="true">
          <div class="toast-header">
            <svg class="bd-placeholder-img rounded mr-2" width="20" height="20"
                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice"
                focusable="false" role="img">
                <rect fill="#007aff" width="100%" height="100%"></rect>
            </svg>
            <strong class="mr-auto">Bootstrap</strong>
            <small>11 mins ago</small>
            <button type="button" class="ml-2 mb-1 close" data-dismiss="toast"
                aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="toast-body">
              Hello, world! This is a toast message.
          </div>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
	import ContentView from '../component/contentView'
	import PhoneView from '../../../components/phoneview'

	export default {
		name: 'NoticeFormView',
		data() {
			return {
				category: '',
				data: {},
				writeData: {},
				viewData: {},
				isViewMode: true,
				showModel: false,
				attachFiles: [],
			}
		},
		updated() {
			if(this.isViewMode == false) {
				window.$('#content').val(this.data.content);

				document.body.onfocus = this.focusedBanner;

				window.$('#content').summernote({
					topMargin: 16,
					height: 383,
					minHeight: 538,
					maxHeight: 800,
					focus: false,
					lang: 'ko-KR',
					disableResizeEditor: true,
					fontSizes: ['8','10','11','12','13','14','15','16','17','18','19','20','24','30','36'],
					fontNames: ['Noto Sans KR', 'Welcome Regular', 'Welcome Bold', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Merriweather'],
					toolbar: [
						['fontname', ['fontname']],
						['style', ['bold','italic','underline']],
						['fontsize', ['fontsize']],
						['color', ['color']],
						['Insert', ['picture']],
						['para', ['paragraph']],
						['view', ['codeview']],
					],
					callbacks: {
						onImageUpload: function(files) {
							console.log('image upload');
							for(let i = 0; i < files.length; i++) {
								self.sendFile(files[i]);
							}
						},
						onKeyup: function () {
							self.data.content = window.$('#content').summernote('code');
						}
					}
				});
			}
		},
		mounted() {
			this.category = this.$route.params.category;
			this.data = this.$route.params.data;
			this.viewData = this.data;
			
			if(this.data.filename != '') {
				this.attachFiles.push({
					filename: this.data.filename,
					fileurl: this.data.fileurl
				})
			}

			if(this.data.filename2 != '') {
				this.attachFiles.push({
					filename: this.data.filename2,
					fileurl: this.data.fileurl2
				})
			}

			if(this.data.filename3 != '') {
				this.attachFiles.push({
					filename: this.data.filename3,
					fileurl: this.data.fileurl3
				})
			}
		},
		methods: {
			clickBack: function () {
				this.$router.push({
					name: 'NoticeForm',
					params: {
						category: this.category,
					}
				})
			},
			clickAttach: function () {
				if(this.attachFiles.length < 3) {
					const el = window.document.querySelector("#file");
					el.click();
				} else {
					alert('첨부 파일은 3개 까지 가능합니다.');
				}
			},
			clickBanner: function () {
				const el = window.document.querySelector("#banner");
				el.click();
			},
			focusedBanner: function () {
				//배너 등록 취소(기 등록된 배너 삭제)
				this.banner = '';
			},
			uploadBanner: function () {
				this.fileUpload(this.$refs.banner, 'banner');
			},
			uploadAttach: function () {
				this.fileUpload(this.$refs.file, 'attach');
			},
			fileUpload: function (e, target) {
				let url = '';
				let name = '';
				let file = e.files.length > 0 ? e.files[0] : undefined;

				if(!file) {
					if(target == 'banner') {
						/*this.$http.post('https://api.heychurch.net/v1/File/delete', {
							file: this.banner
						}).then((res) => {
							if(res.data.success) {
								this.banner = '';
							}
						})*/
						this.banner = '';
					}

					return;
				}

				name = file.name;
				const formData = new FormData();
				formData.append('file', file);

				this.$http.post(
					'https://api.heychurch.net/v1/File/single',
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}
				)
				.then((res) => {
					if(res.data.success === true) {
						url = res.data.images[0];

						if(target == 'banner') {
							this.writeData.banner = url;
						} else {
							this.attachFiles.push({
								filename: name,
								fileurl: url,
							});
						}
					}
				}).catch((err) => {
					console.log(err);
					alert(err);
				});
			},
			clickDelete: function () {
				window.$('#delete-confirm-modal').modal('show');
			},
			deleteSubmit: function () {
				window.$('#delete-confirm-modal').modal('hide');

				this.$http.post('https://asai.sacesapp.com/v1/Post/drun?seq='+this.data.seq.toString())
				.then((res) => {
					if(res.data.success) {
						this.showModel = true;
						this.$router.push({
							name: 'NoticeForm',
							params: {
								category: this.category,
							}
						})
					}
				});
			},
			clickModify: function () {
				this.isViewMode = false; //Modify Mode.
				this.writeData = { ...this.data };
				this.viewData = this.writeData;
			},
			clickCancel: function () {
				this.isViewMode = true;
				this.viewData = this.data;
			},
			clickSave: function () {
				this.data.content = window.$('#content').summernote('code');

				let params = {
					seq: this.writeData.seq,
					subject: this.writeData.subject,
					content: this.writeData.content,
					banner: '',
					fix: '9',
					push: '',
					menu: 'form',
					category: this.writeData.category,
					filename: '',
					fileurl: '',
					filename2: '',
					fileurl2: '',
					filename3: '',
					fileurl3: '',
				};

				if(this.attachFiles.length >= 1) {
					params.filename = this.attachFiles[0].filename;
					params.fileurl = this.attachFiles[0].fileurl;
				}

				if(this.attachFiles.length >= 2) {
					params.filename2 = this.attachFiles[0].filename;
					params.fileurl2 = this.attachFiles[0].fileurl;
				}

				if(this.attachFiles.length >= 3) {
					params.filename3 = this.attachFiles[0].filename;
					params.fileurl3 = this.attachFiles[0].fileurl;
				}
				
				this.$event.$emit('spinner', true);
				this.$http.post('https://asai.sacesapp.com/v1/Post/UpdatePost', params)
				.then((res) => {
					this.$event.$emit('spinner', false);
					if(res.data.success === true) {
						this.$event.$emit('toast','서식이 수정되었습니다');
						window.$('#ok-modal').modal('show');
						this.isViewMode = true;
					}
				})
				.catch(() => {
					this.$event.$emit('spinner', false);
				})
			}
		},
		components: {
			ContentView,
			PhoneView,
		}
	}
</script>

<style scoped>
	.top-title {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.main-box {
		height: calc(100vh - 138px);
	}

	.top-title>span {
		margin-top: 8px;
	}

	#noticehomeview h2 {
		margin-top:12px;
		font-size: 24px;
	}

	.content-area {
		margin-left: 27px;
	}

	#noticehomeview textarea {
		margin-top: 16px;
		height: 383px;
	}

	.button-group {
		display: flex;
		justify-content: right;
	}

	.button-group>button {
		margin-left: 20px;
	}

	.button-group>button:first {
		margin-left: 0px;
	}

	.option-table {
		width: 100%;
	}

	.option-table td {
		width: 50%;
		vertical-align: middle;
		padding:10.5px 15.5px;
	}

	.option-table .option-box {
		display: flex;
		justify-content:space-between;
	}

	.option-box {
		width: 100%;
		display: flex;
		justify-content: space-between;;
	}

	.option-box select {
		width: 124px;
		height: 41px;
	}

	.option-box label {
		font-size: 18px;
		font-weight: 400;
	}

	.option-box h5 {
		font-size: 13px;
		font-weight: 400;
	}

.iphone {
	background-image:url('../../../assets/iphone.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
	height: calc(100vh - 138px);
	width: 430px;
	padding: 70px 30px 50px 30px;
	padding-top: calc(calc(100vh - 847px)/2);
	margin:auto;
}
</style>