<template>
	<div>
		<div class="file-link">
			<label>업로드 방법 : 교육활동관리 > 강사료관리 > 휴가비용 엑셀 다운로드 후 업로드</label>
		</div>
		<table id="noticelist" class="table">
			<thead class="bg-primary text-white">
				<tr>
					<th>지급월</th>
					<th>강사번호</th>
					<th>총액</th>
				</tr>
			</thead>
			<tbody>
			</tbody>
		</table>
	</div>
</template>

<script>
	export default {
		name: 'VacationComponent',
		props: ['list'],
		watch: {
			list: function () {
				window.$.fn.dataTable.ext.errMode = 'none';

				if(window.$.fn.dataTable.isDataTable('#noticelist')) {
					const table = window.$('#noticelist').dataTable();
                    console.log('data', this.list);
					const data = this.list.map((v) => {
						return {
							지급월: v.휴가비용관리,
							강사번호: v.__EMPTY_2,
							총액: v.__EMPTY_10,

						}
					});

					table.fnClearTable();

					if(data.length > 0) {
						table.fnAddData(data);
					}
				} else {
					const data = this.list.map((v) => {
						return {
							지급월: v.휴가비용관리,
							강사번호: v.__EMPTY_2,
							총액: v.__EMPTY_10,
						}
					});

					window.$('#noticelist').dataTable({
						data: data,
						retrive: true,
						columns: [
							{data:'지급월'},
							{data:'강사번호'},
							{data:'총액'},
						]
					});
				}
			}
			/*list: function () {
				const data = this.list.map((v) => {
					return {
						강사번호: v.__EMPTY_2,
						급식비: v.__EMPTY_6,
						지급월: v.급식비,
					}
				});
				
				window.$.fn.dataTable.ext.errMode = 'none';
				window.$('#noticelist').dataTable({
					data: data,
					retrive: true,
					columns: [
						{data:'등록번호'},
						{data:'급식비'},
						{data:'강사료대상월'},
					]
				});
			}*/
		}
	}
</script>

<style scoped>
.file-link {
	margin-bottom: 20px;
}

.file-link a {
	font-size: 16px;
	font-weight: 400px;
}

.file-link label {
	font-size: 16px;
	font-weight: 400;
	color: #1a1e27;
	margin-right: 30px;
}
#noticelist>thead>tr>th {
	font-size: 0.7vw;
}
</style>