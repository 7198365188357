<template>
  <div id="app">
    <div v-if="spinner" class="preloader">
      <div class="lds-ripple">
        <div class="lds-pos"></div>
        <div class="lds-pos"></div>
      </div>
    </div>

    <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
      <aside class="left-sidebar" data-sidebarbg="skin6" style="padding-top:0px;overflow:hidden;">
        <header class="topbar" data-navbarbg="skin6" style="width:260px;">
          <nav class="navbar top-navbar navbar-expand-md">
            <div class="navbar-header" data-logobg="skin6">
            <!-- This is for the sidebar toggle which is visible on mobile only -->
              <a class="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)"><i
              class="ti-menu ti-close"></i></a>
              <!-- ============================================================== -->
              <!-- Logo -->
              <!-- ============================================================== -->
              <div class="navbar-brand">
                <!-- Logo icon -->
                <router-link to="/" style="text-decoration:none;color:#1a1e27">
                  <b class="logo-icon" style="margin-right:16px;">
                    <!-- Light Logo icon -->
                    <img src="./assets/logo.png" style="width:45px;height:39px;" alt="SACES" />
                  </b>
                  <!--End Logo icon -->
                  <!-- Logo text -->
                  <span class="logo-text" style="font-weight:700;font-size:16px;">
                    <!-- Light Logo text -->
                    Admin&nbsp;
                  </span>
                  <span class="logo-text" style="font-weight:400;font-size:16px;">
                    Page
                  </span>
                </router-link>
              </div>

              <a class="topbartoggler d-block d-md-none waves-effect waves-light" href="javascript:void(0)"
              data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><i
              class="ti-more"></i></a>
            </div>

            <div class="navbar-collapse collapse" id="navbarSupportedContent" style="background-color:white;">
              <!-- header menu -->
            </div>
          </nav>
        </header>
        
        <!-- Sidebar scroll-->
        <div class="scroll-sidebar" data-sidebarbg="skin6" style="padding-top:80px;">
          <!-- Sidebar navigation-->
          <nav class="sidebar-nav">
            <ul id="sidebarnav">
              <li :class="{'sidebar-item':true, 'selected': currentPath === ''}">
                <router-link class="sidebar-link sidebar-link" to="/" aria-expanded="false">
                  <svg style="margin-right:13px;" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.602051 6.6L7.82613 1L15.0502 6.6V15.4C15.0502 15.8243 14.8811 16.2313 14.58 16.5314C14.279 16.8314 13.8706 17 13.4449 17H2.2074C1.78164 17 1.37331 16.8314 1.07225 16.5314C0.771185 16.2313 0.602051 15.8243 0.602051 15.4V6.6Z" stroke="#919FB6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                  <span class="hide-menu">Dashboard</span>
                </router-link>
              </li>

              <li class="list-divider"></li>

              <li class="nav-small-cap">
                <span class="hide-menu">서비스 관리</span>
              </li>
              <li :class="{'sidebar-item':true, 'selected': currentPath === 'notice'}">
                <router-link class="sidebar-link sidebar-link" to="/notice/home" aria-expanded="false">
                  <i class="far fa-bell"></i>
                  <span class="hide-menu">공지사항</span>
                </router-link>
              </li>
              <li :class="{'sidebar-item':true, 'selected': currentPath === 'faq'}">
                <router-link class="sidebar-link sidebar-link" to="/faq/home" aria-expanded="false">
                  <i class="far fa-clipboard feather-icon"></i>
                  <span class="hide-menu">FAQ</span>
                </router-link>
              </li>
              <li :class="{'sidebar-item':true, 'selected': currentPath === 'chat'}">
                <router-link class="sidebar-link sidebar-link" to="/chat" aria-expanded="false">
                  <i class="far fa-comments feather-icon"></i>
                  <span class="hide-menu">채팅 상담</span>
                </router-link>
              </li>

              <li class="list-divider"></li>

              <li class="nav-small-cap">
                <span class="hide-menu">기초데이터 관리</span>
              </li>
              <li :class="{'sidebar-item':true, 'selected': currentPath === 'dataview'}">
                <router-link class="sidebar-link sidebar-link" to="/dataview" aria-expanded="false">
                  <i class="fas fa-database feather-icon"></i>
                  <span class="hide-menu">데이터 조회</span>
                </router-link>
              </li>
              <li :class="{'sidebar-item':true, 'selected': currentPath === 'upload'}">
                <router-link class="sidebar-link sidebar-link" to="/upload" aria-expanded="false">
                  <i class="fas fa-upload feather-icon"></i>
                  <span class="hide-menu">기초정보 업로드</span>
                </router-link>
              </li>

              <li class="list-divider"></li>

              <li class="nav-small-cap">
                <span class="hide-menu">회원관리</span>
              </li>
              <li :class="{'sidebar-item':true, 'selected': currentPath === 'user'}">
                <router-link class="sidebar-link sidebar-link" to="/user" aria-expanded="false">
                  <i class="fas fa-users feather-icon"></i>
                  <span class="hide-menu">회원조회 및 권한설정</span>
                </router-link>
              </li>
              <li :class="{'sidebar-item':true, 'selected': currentPath === 'useraccept'}">
                <router-link class="sidebar-link sidebar-link" to="/useraccept" aria-expanded="false">
                  <i class="fas fa-user-plus feather-icon"></i>
                  <span class="hide-menu">회원승인</span>
                </router-link>
              </li>
              <li :class="{'sidebar-item':true, 'selected': currentPath === 'regist'}">
                <router-link class="sidebar-link sidebar-link" to="/regist" aria-expanded="false">
                  <i class="fas fa-user-plus feather-icon"></i>
                  <span class="hide-menu">회원추가(수동)</span>
                </router-link>
              </li>
            </ul>
          </nav>
          <!-- End Sidebar navigation -->
          <div style="text-align:center;position:absolute;bottom:20px;left:39px;">
            <h3 style="font-size:14px;font-weight:700;">The future of the arts</h3>
            <h5 style="font-size:14px;font-weight:400;">상명대학교 문화예술교육사업단</h5>
          </div>
        </div>
        <!-- End Sidebar scroll-->
      </aside>

      <div class="page-wrapper">
        <router-view></router-view>
      </div>
    </div>

    
  </div>
</template>

<script>
  export default {
    name: 'AppPage',
    data() {
      return {
        spinner: false,
        blank: true,
        loggedin: false,
        currentPath: '',
      }
    },
    
    created() {
      if(this.$cookies.isKey('user')) {
        this.$store.state.user = this.$cookies.get('user');
      }

      if(!this.$store.state.user.seq) {
        this.$router.push('/login');
      } else {
        if(this.$store.state.user.seq) {
          this.loggedin = true;
        } else if(this.$cookies.isKey('status')) {
          const email = this.$cookies.get('status');
          this.$http.post('https://asai.sacesapp.com/v1/User/bpl',
          {
            id: email
          })
          .then((res) => {
            if(res.data.success) {
              this.$store.state.user = res.data.user;
              this.loggedin = true;
            } else {
              this.loggedin = false;
              this.$router.push('/login');
            }
          })
        } else {
          this.loggedin = false;
          this.$router.push('/login');
        }
      }

      /*
      if(!this.$store.state.user.seq) {
        console.log("???", this.$route.path, this.$store.state.user.seq);
        if(this.$cookies.isKey('status')) {
          const email = this.$cookies.get('status');
          this.$http.post('https://asai.sacesapp.com/v1/User/bpl',
          {
            id: email
          })
          .then((res) => {
            if(res.data.success) {
              this.$store.state.user = res.data.user;
              this.loggedin = true;
              this.blank = false;
              this.$forceUpdate();

            } else {
              if(this.$cookies.isKey('email')) {
                this.saveEmail = true;
                this.email = this.$cookies.get('email');
              }
            }
          })
        } else {
          this.blank = false;
          this.loggedin = true;
          this.$forceUpdate();
          if(this.$cookies.isKey('email')) {
            this.saveEmail = true;
            this.email = this.$cookies.get('email');
          }
        }
      } else {
        this.blank = false;
        this.loggedin = true;
      }*/

      this.$event.$on('toast', () => {
        //window.$('.toast').toast('show');
        //console.log('hi!');
      });

      this.$event.$on('spinner', (onoff) => {
        this.spinner = onoff;
      })
    },
    watch: {
      '$route' (to) {
        this.currentPath = to.path.split('/')[1];
        console.log(this.currentPath);
      }
    }
  }
</script>

<style>
@font-face {
  font-family: 'Welcome Bold';
  src: url('assets/fonts/Welcome-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'Welcome Regular';
  src: url('assets/fonts/Welcome-Regular.woff') format('woff');
  font-weight: normal;
}

.page-wrapper {
  padding-top:0px !important;
}
.page-wrapper>.container-fluid {
  padding:17px !important;
}

.dim {
  position: absolute;
  z-index: 900;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.25);
}

html {
  font-family: 'Noto Sans KR';
}

body {
  font-family: 'Noto Sans KR';
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}



.preloader {
  background-color: rgba(0,0,0,0.5);
}

.lds-ripple .lds-pos {
  border: 2px solid white !important;
}

.login-box>.input-group>.error {
  border: 1px solid red;
}

.login-box>.input-group>.errorText {
  margin-left: 10px;
  margin-top: 2px;
  font-weight: 400;
  font-size: 16px;
  color: red;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.modal-backdrop {
  z-index: 0;
}
</style>
