<template>
	<div id="faq-write">
		<div class="row">
			<div class="col-lg-8">
				<div class="card">
					<div class="card-body">
						<div class="main-box">
							<div @click="clickBack">
								<svg width="19" height="33" viewBox="0 0 19 33" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								<rect width="19" height="33" fill="url(#pattern0)"/>
								<defs>
								<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
								<use xlink:href="#image0_5035_2502" transform="scale(0.0526316 0.030303)"/>
								</pattern>
								<image id="image0_5035_2502" width="19" height="33" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAhCAYAAAA74pBqAAAAyUlEQVRIDe3UsQrCMBAG4D54BwcXJxWc4+ZgB4eC4BtkEweH4gs4dVEHh/KXIJFoSe7+2kl6S3Ih+bgcIRkGjGxACyPGd/MPe1bbEsvNCbegG72uWdsdpjOD7fERUODfWQxyKlVZCqIwCVJjGkiFaSERY6AkxkIJ7ImqMMjnJc53t00X8afRXGHXBvlirwbjmCvGg6sDKkWFaYwEZYwAdZgS1GMKkMMEkMcCcFJcXPaOfpg/3vjJa/wN+7S4z/HrbCcdK+u0RFxoAURzl7tvYD0wAAAAAElFTkSuQmCC"/>
								</defs>
								</svg>
							</div>

							<div v-if="isViewMode" class="content-area">
								<h2>FAQ 열람하기</h2>

								<div class="top-title">
									<span>대상</span>
									<div style="width:124px;height:41px;background-color:#e9ecef;color:#4f5467;padding:8px 15px;">{{data.category}}</div>
								</div>
								<div class="form-group" style="margin-top:25px;">
									<p class="form-control">{{data.subject}}</p>
									<p class="form-control content-box" v-html="brText"></p>
								</div>

								<div class="button-group">
									<button type="button" class="btn waves-effect waves-light btn-secondary" @click="clickDelete">삭제하기</button>
									<button type="button" class="btn waves-effect waves-light btn-primary" @click="clickModify">수정하기</button>
								</div>
							</div>

							<div v-else class="content-area">
								<h2>FAQ 수정하기</h2>

								<div class="top-title">
									<span>대상</span>
									<select class="form-control" v-model="writeData.category">
										<option value="공통">공통</option>
										<option value="예술강사">예술강사</option>
										<option value="운영학교">운영학교</option>
									</select>
								</div>
								<div class="form-group">
									<form class="mt-4">
										<input type="text" style="margin-bottom:15px;" class="form-control" v-model="writeData.subject">
										<textarea id="content" class="form-control" v-model="writeData.content">
										</textarea>
									</form>
								</div>

								<div class="button-group">
									<button type="button" class="btn waves-effect waves-light btn-secondary" @click="clickCancel">취소하기</button>
									<button type="button" class="btn waves-effect waves-light btn-primary" @click="clickSave">등록하기</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="card">
					<div class="card-body">
						<div class="iphone">
							<phone-view :data="viewData" mode="faq" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Primary Header Modal -->
		<div id="ok-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="primary-header-modalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-primary">
										<h4 class="modal-title" id="primary-header-modalLabel">알림
										</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
									<p>{{modalMessage}}</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal" @click="onOk">Close</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->

		<!-- Danger Header Modal -->
		<div id="delete-confirm-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="danger-header-modalLabel" aria-hidden="false">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-danger">
										<h4 class="modal-title" id="danger-header-modalLabel">삭제하기</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
										<p>계속하시겠습니까?</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal">취소하기</button>
										<button type="button" class="btn btn-danger" @click="deleteSubmit">삭제하기</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->
	</div>
</template>

<script>
	import PhoneView from '../../../components/phoneview'

	export default {
		name: 'FaqWrite',
		data() {
			return {
				isViewMode: true,
				category: '',
				data: '',
				writeData: {},
				viewData: {},
				text: '',
				list: [],
				curSeq: -1,
				categoryOn: false,
				brText: '',
				modalMessage: '',
				deleteMode: false,
			}
		},
		mounted() {
			this.category = this.$route.params.category;
			this.data = this.$route.params.data;
			this.viewData = this.data;
			this.brText = this.data.content.replace(/(?:\r\n|\r|\n)/g, '<br />');
			//this.list.push(this.data);

			/*window.$('#content').val(this.data.content);

			window.$('#content').summernote({
				topMargin: 16,
				height: 383,
				minHeight: 383,
				maxHeight: 800,
				focus: false,
				lang: 'ko-KR',
				toolbar: [
					['style', ['bold','italic','underline']],
					['fontsize', ['fontsize']],
					['color', ['color']],
					['Insert', ['picture']],
				]
			})*/
		},
		methods: {
			clickBack: function () {
				this.$router.push({
					name: 'FaqHome',
					params: {
						category: this.category,
					}
				})
			},
			onOk: function () {
				if(this.deleteMode) {
					this.$router.push({
						name: 'FaqHome',
						params: {
							category: this.parentCategory,
						}
					})
				}
			},
			deleteSubmit: function () {
				window.$('#delete-confirm-modal').modal('hide');

				this.$http.post('https://asai.sacesapp.com/v1/Post/drun?seq='+this.data.seq.toString())
				.then((res) => {
					if(res.data.success) {
						this.showModel = true;
						this.deleteMode = true;
						this.modalMessage = 'FAQ가 삭제되었습니다';
						window.$('#ok-modal').modal('show');
					}
				});
			},
			clickDelete: function () {
				this.showModel = true;

				window.$('#delete-confirm-modal').modal('show');
			},
			clickModify: function () {
				this.writeData = { ...this.data };
				this.viewData = this.writeData;
				this.isViewMode = false; //Modify Mode.
				console.log(this.writeData);
			},
			clickCancel: function () {
				this.viewData = this.data;
				this.isViewMode = true;
			},
			clickFaqItem: function (idx) {
				//this.list[idx].active = !this.list[idx].active;
				if(this.curSeq != this.list[idx].seq) {
				this.curSeq = this.list[idx].seq;
				} else {
					this.curSeq = -1;
				}

			},
			clickSave: function () {
				let params = {
					seq: this.writeData.seq,
					subject: this.writeData.subject,
					content: this.writeData.content,
					banner: '',
					fix: '9',
					push: '',
					menu: 'faq',
					category: this.writeData.category,
				};
				
				this.$http.post('https://asai.sacesapp.com/v1/Post/UpdatePost', params)
				.then((res) => {
					if(res.data.success === true) {
						this.data = { ...this.writeData }
						this.viewData = this.data;
						this.brText = this.data.content.replace(/(?:\r\n|\r|\n)/g, '<br />');
						this.$event.$emit('toast','FAQ가 수정되었습니다');
						this.modalMessage = 'FAQ가 수정되었습니다';
						window.$('#ok-modal').modal('show');
						this.isViewMode = true;
					}
				})
			}
		},
		components: {
			PhoneView,
		}
	}
</script>

<style scoped>
	.top-title {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.top-title>span {
		margin-top: 8px;
	}

	.top-title>select {
		width: 124px;
		height: 41px;
	}

	#faq-write h2 {
		margin-top:12px;
		font-size: 24px;
	}

	.main-box {
		height: calc(100vh - 135px);
	}

	.content-area {
		margin-left: 27px;
	}

	#faq-write textarea {
		margin-top: 16px;
		height: calc(100vh - 400px);
	}

.iphone {
	background-image:url('../../../assets/iphone.png');
	background-repeat: no-repeat;
	background-size: cover;
	height: 847px;
	width: 430px;
	padding: 70px 30px 50px 30px;
	margin:0 auto;
}

	.button-group {
		display: flex;
		justify-content: right;
	}

	.button-group>button {
		margin-left: 20px;
	}

	.button-group>button:first {
		margin-left: 0px;
	}


.content-box {
	height: calc(100vh - 400px);
	overflow: auto;
}

.iphone ul {
		list-style: none;
		padding: 0px 10px;
		margin-top:20px;
		margin-left:5px;
		margin-right:5px;
	}

	.iphone ul li {
		padding: 15px 16px;
		background-color: #e4e6e8;
		border-radius: 10px;
		text-align: left;
		min-height: 60px;
		margin-bottom: 20px;
	}

	.iphone ul li .title {
		display: flex;
		height: 36px;
		justify-content: space-between;
		align-items: center;
	}

	.iphone .left {
		transform: translate(0%,0%)
	}

	.iphone .right {
		padding-top:0px;
		margin-top:-7px;
	}

	.iphone ul li h1 {
		font-size: 16px;
		font-weight: 500;
		margin:0;
		margin-right: 7px;
	}

	.iphone ul li h3 {
		font-size: 11px;
		font-weight: 400;
		padding:0;
		margin:0;
		margin-top: 5px;
	}

	.iphone .content {
		margin-top: 16px;
	}

	.iphone p {
		word-break: keep-all;
		font-size: 15px;
		font-weight: 400;
	}

	.iphone .margin-down {
		margin-top: 7px;
	}

	.iphone .margin-up {
		margin-top: -3px;
	}
</style>