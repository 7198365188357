<template>
	<div id="ContactCategory">
		<p>
			상담 유형별 빈도 추정을 위한 기능입니다. 상담을 마친 후 상담유형을 기재하고 enter를 치면 자동으로 카운트 됩니다. 주요 키워드만 입력해주세요
		</p>
		<ul>
			<li v-for="(item, idx) in category" :key="idx" :style="item.style" @click="saveKeyword(item.keyword)">
				{{item.keyword}}
			</li>
		</ul>
		<input type="text" class="form-control" style="height:43px;font-size: 14px;" v-model="keyword" v-on:keyup.enter="saveKeyword(keyword)" placeholder="키워드를 입력해주세요">

		<!-- Primary Header Modal -->
		<div id="category-ok-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="primary-header-modalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-primary">
										<h4 class="modal-title" id="primary-header-modalLabel">알림
										</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
										<h5 class="mt-0">상담유형</h5>
										<p>상담유형 추가가 완료되었습니다</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal">Close</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->
	</div>
</template>

<script>
	export default {
		name: 'ContactCatrgoryComponent',
		data() {
			return {
				keywords: ['출산휴가','재직증명서','강사재배치','시수포기','추가배치','휴업문의','시수확인','앱사용방법','강사등록'],
				colors: ['#5f76e8','#22ca80','#fdc16a','#ff4f70','#a0d218','#15b7c6','#d82184','#dd571d','#663fe6'],
				category: [],
				keyword: '',
			}
		},
		created() {
			this.loadKeywords();
		},
		methods: {
			loadKeywords: function () {
				this.$http.get('https://asai.sacesapp.com/v1/Chat/contactcategory')
				.then((res) => {
					let idx = 0;
					if(res.data.success) {
						this.category = [];
						res.data.list.forEach(x => {
							if(idx < 9) {
								this.category.push({
									keyword: x.keyword,
									style: {
										backgroundColor: this.colors[idx++],
										color: 'white',
									}
								});
							}
						});
					}
				})
			},
			saveKeyword: function (keyword) {
				this.$http.post('https://asai.sacesapp.com/v1/Chat/regkey?keyword='+keyword)
				.then((res) => {
					if(res.data.success) {
						window.$('#category-ok-modal').modal('show');
						this.loadKeywords();
					}
				})
			}
		}
	}
</script>

<style scoped>
#ContactCategory ul {
	list-style: none;
	padding: 0;
	display: float;
	width:370px;
	margin:0 auto;
	margin-bottom: 35.6px;
}

#ContactCategory>ul>li {
	float: left;
	width:115px;
	height: 43px;
	text-align: center;
	box-sizing: border-box;
	font-size: 15px;
	font-weight: 400;
	margin-right:9px;
	margin-bottom:9px;
	padding: 10px 10px;
	cursor: pointer;
	overflow: hidden;
}

#ContactCategory>p {
	color: #919fb6;
	font-size: 18px;
	font-weight: 400;
}

#ContactCategory>input[type="text"] {
	font-size: 14px;
	font-weight: 400;
	color: black;
	border: 1px solid #e4e6e8;
	width: 100%;
}

#ContactCategory>input[type="text"]::placeholder {
	color: #919fb6;
}

#ContactCategory>ul>li:nth-child(3) {
	margin-right:0px;
}

#ContactCategory>ul>li:nth-child(6) {
	margin-right:0px;
}

#ContactCategory>ul>li:nth-child(9) {
	margin-right:0px;
}
</style>