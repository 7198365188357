import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VdtnetTable from 'vue-datatables-net'
import Raphael from 'raphael/raphael'
import { DonutChart, LineChart, BarChart,  } from 'vue-morris'
import 'swiper/css/swiper.css'
import VueCookies from 'vue-cookies'

global.Raphael = Raphael

import TopMenu from './components/topmenu'

Vue.use(VueCookies);
Vue.$cookies.config("1d");

Vue.prototype.$http = axios;
Vue.prototype.$event = new Vue();

Vue.config.productionTip = false
Vue.component('TopMenu', TopMenu)
Vue.component('VdtnetTable', VdtnetTable)
Vue.component('DonutChart', DonutChart)
Vue.component('LineChart', LineChart)
Vue.component('BarChart', BarChart)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
