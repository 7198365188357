<template>
	<div id="callregist">
		<ul style="min-height:390px;">
			<li v-for="(item, idx) in list" :key="idx">
				<svg @click="clickClose(item.seq)" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<line x1="0.707107" y1="1.29289" x2="12.7071" y2="13.2929" stroke="black" stroke-width="2"/>
					<line x1="12.7071" y1="1.70711" x2="0.707106" y2="13.7071" stroke="black" stroke-width="2"/>
				</svg>
				<h1>{{item.name}} {{item.code}}</h1>
				<p>{{item.memo}}</p>
			</li>
		</ul>
		
		<nav aria-label="..." style="display:table;margin:auto;">
			<ul class="pagination">
				<li class="page-item">
					<a class="page-link" @click="onPrev"
						tabindex="-1">Previous</a>
				</li>
				<li :class="{'page-item':true, 'active':(idx) == curPage}" v-for="(item, idx) in pageList" :key="idx" @click="onGo(idx)">
				<a class="page-link">{{idx+1}}</a></li>
				<li class="page-item">
						<a class="page-link" @click="onNext">Next</a>
				</li>
			</ul>
		</nav>

		<div id="callreserve-delete-confirm-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="danger-header-modalLabel" aria-hidden="false">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-danger">
										<h4 class="modal-title" id="danger-header-modalLabel">삭제하기</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
										<p>계속하시겠습니까?</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal">취소하기</button>
										<button type="button" class="btn btn-danger" @click="deleteSubmit">삭제하기</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->

		<!-- Primary Header Modal -->
		<div id="callreserve-ok-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="primary-header-modalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-primary">
										<h4 class="modal-title" id="primary-header-modalLabel">알림
										</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
									<p>전화예약이 삭제되었습니다</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal">Close</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->
	</div>
</template>

<script>
	export default {
		name: 'CallReserveComponent',
		data() {
			return {
				list: [],
				sourceList: [],
				begin: 0,
				limit: 4,
				curPage: 0,
				pageList: [],
				user: {
					seq: 0,
					name: '',
				},
				closeSeq: -1,
			}
		},
		mounted() {
			this.$http.get('https://asai.sacesapp.com/v1/Chat/listcalls')
			.then((res) => {
				if(res.data.success) {
					this.sourceList = res.data.list;
					this.list = this.sourceList.length <= this.limit ? this.sourceList : this.sourceList.slice(this.begin, this.limit);

					const page = this.sourceList.length / this.limit;
					for(let i = 0; i < page; i++) {
						this.pageList.push({
							startIdx: i * this.limit,
						})
					}
				}
			})
		},
		methods: {
			onPrev: function () {
				if(this.curPage > 0) this.curPage--;
				this.pageSlice();
			},
			onGo: function (idx) {
				this.curPage = idx;
				this.pageSlice();
			},
			onNext: function () {
				if(this.curPage < (this.pageList.length-1)) this.curPage++;
				this.pageSlice();
			},
			pageSlice: function () {
				const start = this.pageList[this.curPage].startIdx;
				this.list = this.sourceList.slice(start, start + this.limit);
			},
			clickClose: function (cseq) {
				this.closeSeq = cseq;
				window.$('#callreserve-delete-confirm-modal').modal('show');
			},
			deleteSubmit: function () {
				window.$('#callreserve-delete-confirm-modal').modal('hide');

				this.$http.post(`https://asai.sacesapp.com/v1/Chat/closecall?useq=${this.user.seq}&cseq=${this.closeSeq}`)
				.then((res) => {
					if(res.data.success) {
						this.sourceList = this.sourceList.filter(x => x.seq != this.closeSeq);
						this.begin = this.pageList[this.curPage].startIdx;

						this.list = this.sourceList.length <= this.limit ? this.sourceList : this.sourceList.slice(this.begin, this.begin+this.limit);

						const page = this.sourceList.length / this.limit;
						this.pageList = [];
						for(let i = 0; i < page; i++) {
							this.pageList.push({
								startIdx: i * this.limit,
							})
						}

						if(this.curPage > (this.pageList.length-1)) {
							this.curPage--;
						}

						this.closeSeq = -1;
						console.log(this.closeSeq);
						window.$('#callreserve-ok-modal').modal('show');
						this.$event.$emit('toast', '전화예약 콜백을 완료했습니다');
					}
				})
			}
		}
	}
</script>

<style scoped>
	#callregist>ul {
		margin: 0;
		list-style: none;
		padding: 0;
	}

	#callregist>ul>li {
		position: relative;
		padding: 13px;
		background-color: #efefef;
		margin-bottom: 25px;
	}

	#callregist>ul>li>svg {
		position: absolute;
		right: 13px;
		top: 13;
	}

	#callregist>ul>li>h1 {
		font-size: 14px;
		font-weight: 400;
		color: black;
		margin:0;
		margin-bottom:10px;
	}

	#callregist>ul>li>p {
		font-size: 13px;
		font-weight: 350;
		margin:0;
		color: block;
	}
</style>