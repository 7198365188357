<template>
	<div>
		<div class="file-link">
			<label>업로드 방법 : 강사선발관리 > 강사선발결과 엑셀 다운로드 후 업로드</label>
		</div>
		<table id="noticelist" class="table">
			<thead class="bg-primary text-white">
				<tr>
					<th>선발지역</th>
					<th>강사명</th>
					<th>강사번호</th>
					<th>이메일</th>
					<th>휴대전화</th>
					<th>주소</th>
					<th>분야</th>
					<th>교급</th>
					<th>자격기준</th>
					<th>상태</th>
				</tr>
			</thead>
			<tbody>
			</tbody>
		</table>
	</div>
</template>

<script>
	export default {
		name: 'TutorComponent',
		props: ['list'],
		watch: {
			list: function () {
				window.$.fn.dataTable.ext.errMode = 'none';
				if(window.$.fn.dataTable.isDataTable('#noticelist')) {
					const table = window.$('#noticelist').dataTable();
					const data = this.list.map((v) => {
						return {
						선발지역: v.강사선발결과,
						강사명: v.__EMPTY,
						강사번호: v.__EMPTY_1,
						이메일: v.__EMPTY_3,
						휴대전화: v.__EMPTY_2,
						주소: v.__EMPTY_4,
						분야: v.__EMPTY_5,
						교급: v.__EMPTY_6,
						자격기준: v.__EMPTY_7,
						상태: v.__EMPTY_13,
					}});

					table.fnClearTable();

					if(data.length > 0) {
						table.fnAddData(data);
					}
				} else {
					const data = this.list.map((v) => {
						return {
						선발지역: v.강사선발결과,
						강사명: v.__EMPTY,
						강사번호: v.__EMPTY_1,
						이메일: v.__EMPTY_3,
						휴대전화: v.__EMPTY_2,
						주소: v.__EMPTY_4,
						분야: v.__EMPTY_5,
						교급: v.__EMPTY_6,
						자격기준: v.__EMPTY_7,
						상태: v.__EMPTY_13,
					}});

					window.$('#noticelist').dataTable({
						data: data, //this.list,
						retrive: true,
						columns: [
							{data:'선발지역'},
							{data:'강사명'},
							{data:'강사번호'},
							{data:'이메일'},
							{data:'휴대전화'},
							{data:'주소'},
							{data:'분야'},
							{data:'교급'},
							{data:'자격기준'},
							{data:'상태'},
						]
					});
				}
			}
		}
	}
</script>

<style scoped>
.file-link {
	margin-bottom: 20px;
}

.file-link a {
	font-size: 16px;
	font-weight: 400px;
}

.file-link label {
	font-size: 16px;
	font-weight: 400;
	color: #1a1e27;
	margin-right: 30px;
}
#noticelist>thead>tr>th {
	font-size: 0.7vw;
}
</style>