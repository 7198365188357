<template>
	<div id="upload-home">
		<div class="row">
			<div class="col-lg-12">
				<div class="top-info">
					<label>기초정보</label>
					<span>연초 및 상시(정보 변경 시) : 학교정보 / 강사정보 / 강사배치</span>
				</div>
				<div class="top-info">
					<label>업로드 시기</label>
					<span>월말 : 출강결과 / 급식비</span>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<category-menu :category="category" mode="upload" :menu="categoryMenu" @change="changeCategory" @load="onLoad" @submit="onSubmit"></category-menu>

						<component :is="subComponent" :list="list" temp="안녕"></component>
						<input type="file" style="visibility: hidden;" ref="openXls"  @change="loadXls" accept=".xls, .xlsx">
						<xlsx-read :file="file">
							<xlsx-json @parsed="jsonLoaded">
							</xlsx-json>
						</xlsx-read>
					</div>
				</div>
			</div>
		</div>

		<!-- Primary Header Modal -->
		<div id="ok-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="primary-header-modalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-primary">
										<h4 class="modal-title" id="primary-header-modalLabel">알림
										</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
										<h5 class="mt-0">기초자료등록</h5>
										<p>업로드가 완료되었습니다</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal">Close</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->
	</div>
</template>

<script>
	import CategoryMenu from '../../components/category'
	import { XlsxRead, XlsxJson } from 'vue-xlsx/dist/vue-xlsx.es'
	
	import SchoolComponent from './SchoolComponent'
	import TutorComponent from './TutorComponent'
	import AssignComponent from './AssignComponent'
	import TeachComponent from './TeachComponent'
	import PaymentComponent from './PaymentComponent'
	import MealComponent from './MealComponent'
	import VacationComponent from './VacationComponent'
	import OtherComponent from './OtherComponent'
	import AgreementComponent from './AgreementComponent'

	export default {
		name: 'UploadPage',
		data() {
			return {
				subComponent: null,
				category: '학교정보',
				categoryMenu: ['학교정보','강사정보','강사배치','출강결과','강사료','급식비','휴가비용','기타비용','교육활동동의'],
				list: [],
				fields: ['배너여부'],
				file: null,
				selectedSheet: null,
				sheetName: null,
				sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
				collection: [{ a: 1, b: 2 }]
			}
		},
		mounted() {
			this.subComponent = SchoolComponent;
		},
		methods: {
			jsonLoaded: function (json) {
				this.$event.$emit('spinner', false);
				this.list = json.splice(1);
			},
			onLoad: function () {
				this.file = null;
				this.$refs.openXls.click();
			},
			onSubmit: function () {
				if(this.category == '학교정보') {
					this.submitSchool();
				}

				if(this.category == '강사정보') {
					this.submitTutor();
				}

				if(this.category == '강사배치') {
					this.submitAssign();
				}

				if(this.category == '출강결과') {
					this.submitResult();
				}

				if(this.category == '강사료') {
					this.submitPayment();
				}

				if(this.category == '급식비') {
					this.submitMeal();
				}

				if(this.category == '휴가비용') {
					this.submitVacation();
				}

				if(this.category == '기타비용') {
					this.submitOther();
				}

				if(this.category == '교육활동동의') {
					this.submitAgreement();
				}

			},
			submitSchool: function () {
				const schools = this.list.map(x => {
					const item = {
						sido: x['학교선정결과'].toString(),
						schoolName: x['__EMPTY'].toString(),
						schoolCode: x['__EMPTY_1'].toString(),
						address: x['__EMPTY_3'].toString(),
						teacher: '',
						code: '',
						email: '',
						phone: x['__EMPTY_2'].toString(),
						sleep: 'N'
					}

					return item;
				});

				this.$event.$emit('spinner', true);
				this.$http.post('https://asai.sacesapp.com/v1/Upload/school', schools)
				.then((res) => {
					this.$event.$emit('spinner', false);
					if(res.data.success) {
						window.$('#ok-modal').modal('show');
					}
				})
				.catch((err) => {
					console.log(err);
					this.$event.$emit('spinner', false);
				})
			},
			submitTutor: function () {
				const schools = this.list.map(x => {
					const item = {
						sido: x['강사선발결과'].toString(),
						name: x['__EMPTY'].toString(),
						code: x['__EMPTY_1'].toString(),
						email: x['__EMPTY_3'].toString(),
						phone: x['__EMPTY_2'].toString(),
						address: x['__EMPTY_4'].toString(),
						tutorfield: x['__EMPTY_5'].toString(),
						classlevel: x['__EMPTY_6'].toString(),
						qualtype: x['__EMPTY_7'].toString(),
						status: x['__EMPTY_13'].toString()
					}

					return item;
				});

				this.$event.$emit('spinner', true);
				this.$http.post('https://asai.sacesapp.com/v1/Upload/tutor', schools)
				.then((res) => {
					this.$event.$emit('spinner', false);
					if(res.data.success) {
						window.$('#ok-modal').modal('show');
					}
				})
				.catch((err) => {
					this.$event.$emit('spinner', false);
					console.log(err);
				})
			},
			submitAssign: function () {
				const schools = this.list.map(x => {

					const item = {
						schoolcode: x['__EMPTY_3'].toString(),
						schoolloc: x['__EMPTY_1'].toString(),
						field: x['__EMPTY_4'].toString(),
						curriculum: x['__EMPTY_6'].toString(),
						schoolarrtime: x['__EMPTY_7'].toString(),
						tutorcode: x['__EMPTY_11'].toString(),
						tutorloc: x['__EMPTY_9'].toString(),
						tutorarrstatus: x['__EMPTY_13'].toString(),
						tutorarrtime: x['__EMPTY_14'].toString(),
						period: x['강사배치현황목록'].toString(),
					}

					return item;
				});

				this.$event.$emit('spinner', true);
				this.$http.post('https://asai.sacesapp.com/v1/Upload/assign', schools)
				.then((res) => {
					this.$event.$emit('spinner', false);
					if(res.data.success) {
						window.$('#ok-modal').modal('show');
					}
				})
				.catch((err) => {
					this.$event.$emit('spinner', false);
					console.log(err);
				})
			},
			submitResult: async function () {
				let clearTarget = [];
				const schools = this.list.map(x => {
					const item = {
						schoolcode: x['__EMPTY_1'].toString(),
						field: x['__EMPTY_2'].toString(),
						curriculum: x['__EMPTY_3'].toString(),
						tutorcode: x['__EMPTY_6'].toString(),
						lecturedate: x['__EMPTY_7'].toString(),
						lecturetime: x['__EMPTY_10'].toString(),
						finishedhour: x['__EMPTY_9'].toString(),
						lecturetitle: '',
						tutorcheckdate: x['__EMPTY_11'] ? x['__EMPTY_11'].toString() : '',
						teachercheckdate: x['__EMPTY_14'] ? x['__EMPTY_14'].toString() : '',
						paymonth: '',
						trafficpay: '0',
						remotepay: '0',
						lecturepay: '0',
					}

					clearTarget.push({
						schoolcode: item.schoolcode,
						lecturedate: item.lecturedate,
						tutorcode: item.tutorcode
					});

					return item;
				});

				/*let clearTarget = [];
				schools.forEach(x => {
					const idx = clearTarget.findIndex(y => y.schoolcode == x.schoolcode && y.lecturemonth == x.lecturedate.substr(0,7) && y.tutorcode == x.tutorcode);
					if(idx < 0) {
						clearTarget.push({
							schoolcode: x.schoolcode,
							lecturedate: x.lecturedate,
							tutorcode: x.tutorcode
						});
					}
				});*/

				this.$event.$emit('spinner', true);
				this.$http.post('https://asai.sacesapp.com/v1/Upload/cleanPayment', clearTarget)
				.then(() => {
					//if(res.data.success) {
						this.$http.post('https://asai.sacesapp.com/v1/Upload/payment', schools)
						.then((res) => {
							this.$event.$emit('spinner', false);
							if(res.data.success) {
								window.$('#ok-modal').modal('show');
							}
						})
						.catch((err) => {
							this.$event.$emit('spinner', false);
							console.log(err);
						})
					//} else {
					//	this.$event.$emit('spinner', false);
					//}
				})
				.catch((err) => {
					this.$event.$emit('spinner', false);
					console.log(err);
				})
				
			},
			submitPayment: async function () {
				let clearTarget = [];
				const schools = this.list.map(x => {
					const item = {
						paymonth: x['강사료'].toString(),
						schoolcode: x['__EMPTY_3'].toString(),
						tutorcode: x['__EMPTY_7'].toString(),
						finishedhour: x['__EMPTY_10'].toString(),
						lecturepay: x['__EMPTY_11'].toString(),
						trafficpay: x['__EMPTY_12'].toString(),
						remotepay: x['__EMPTY_13'].toString(),
						holidaypay: x['__EMPTY_14'].toString(),
						disabledpay: x['__EMPTY_15'].toString(),
						totalpay: x['__EMPTY_16'].toString(),
					}

					clearTarget.push({
						schoolcode: item.schoolcode,
						paymonth: item.paymonth,
						tutorcode: item.tutorcode
					});

					return item;
				});

				/*let clearTarget = [];
				schools.forEach(x => {
					const idx = clearTarget.findIndex(y => y.schoolcode == x.schoolcode && y.lecturemonth == x.lecturedate.substr(0,7) && y.tutorcode == x.tutorcode);
					if(idx < 0) {
						clearTarget.push({
							schoolcode: x.schoolcode,
							lecturedate: x.lecturedate,
							tutorcode: x.tutorcode
						});
					}
				});*/

				this.$event.$emit('spinner', true);
				this.$http.post('https://asai.sacesapp.com/v1/Upload/cleanPaymentNew', clearTarget)
				.then(() => {
					//if(res.data.success) {
						this.$http.post('https://asai.sacesapp.com/v1/Upload/paymentNew', schools)
						.then((res) => {
							this.$event.$emit('spinner', false);
							if(res.data.success) {
								window.$('#ok-modal').modal('show');
							}
						})
						.catch((err) => {
							this.$event.$emit('spinner', false);
							console.log(err);
						})
					//} else {
					//	this.$event.$emit('spinner', false);
					//}
				})
				.catch((err) => {
					this.$event.$emit('spinner', false);
					console.log(err);
				})
				
			},
			submitMeal: function () {
				const schools = this.list.map(x => {
					const item = {
						tutorcode: x['__EMPTY_2'].toString(),
						mealpay: x['__EMPTY_6'].toString(),
						lecturemonth: '',
						paymonth: x['급식비'].toString(),
					}

					return item;
				});

				this.$event.$emit('spinner', true);
				this.$http.post('https://asai.sacesapp.com/v1/Upload/meal', schools)
				.then((res) => {
					this.$event.$emit('spinner', false);
					if(res.data.success) {
						window.$('#ok-modal').modal('show');
					}
				})
				.catch((err) => {
					this.$event.$emit('spinner', false);
					console.log(err);
				})
			},
			submitVacation: function () {
				const schools = this.list.map(x => {
					const item = {
						paymonth: x['휴가비용관리'].toString(),
						tutorcode: x['__EMPTY_2'].toString(),
						totalpay: x['__EMPTY_10'].toString(),
					}

					return item;
				}).splice(1);

				this.$event.$emit('spinner', true);
				this.$http.post('https://asai.sacesapp.com/v1/Upload/vacationbonus', schools)
				.then((res) => {
					this.$event.$emit('spinner', false);
					if(res.data.success) {
						window.$('#ok-modal').modal('show');
					}
				})
				.catch((err) => {
					this.$event.$emit('spinner', false);
					console.log(err);
				})
			},
			submitOther: function () {
				const schools = this.list.map(x => {
					const item = {
						tutorcode: x['__EMPTY_7'].toString(),
						paymonth: x['기타비용관리'].toString(),
						totalpay: x['__EMPTY_12'].toString(),
						previouspay: x['__EMPTY_17'].toString(),
					}

					return item;
				})

				this.$event.$emit('spinner', true);
				this.$http.post('https://asai.sacesapp.com/v1/Upload/otherpay', schools)
				.then((res) => {
					this.$event.$emit('spinner', false);
					if(res.data.success) {
						window.$('#ok-modal').modal('show');
					}
				})
				.catch((err) => {
					this.$event.$emit('spinner', false);
					console.log(err);
				})
			},
			submitAgreement: function () {
				const schools = this.list.map(x => {
					const item = {
						tutorcode: x['__EMPTY_1'].toString(),
						agreement: x['__EMPTY_6'].toString(),
						agreedate: x['__EMPTY_7'].toString(),
					}

					return item;
				})

				this.$event.$emit('spinner', true);
				this.$http.post('https://asai.sacesapp.com/v1/Upload/agreement', schools)
				.then((res) => {
					this.$event.$emit('spinner', false);
					if(res.data.success) {
						window.$('#ok-modal').modal('show');
					}
				})
				.catch((err) => {
					this.$event.$emit('spinner', false);
					console.log(err);
				})
			},
			loadXls: function (e) {
				if(e.target.files.length > 0) {
					this.$event.$emit('spinner', true);
					this.file = e.target.files ? e.target.files[0] : null;
				}
			},
			changeCategory: function (v) {
				this.category = v;

				this.list = [];

				switch(v) {
					case '학교정보' :
						this.subComponent = SchoolComponent;
						break;
					case '강사정보' :
						this.subComponent = TutorComponent;
						break;
					case '강사배치' :
						this.subComponent = AssignComponent;
						break;
					case '출강결과' :
						this.subComponent = TeachComponent;
						break;
					case '강사료' :
						this.subComponent = PaymentComponent;
						break;
					case '급식비' :
						this.subComponent = MealComponent;
						break;
					case '휴가비용' :
						this.subComponent = VacationComponent;
						break;
					case '기타비용' :
						this.subComponent = OtherComponent;
						break;
					case '교육활동동의' :
						this.subComponent = AgreementComponent;
						break;
				}
			}
		},
		components: {
			CategoryMenu,
			SchoolComponent,
			TutorComponent,
			AssignComponent,
			TeachComponent,
			PaymentComponent,
			MealComponent,
			VacationComponent,
			OtherComponent,
			AgreementComponent,
			XlsxRead,
			XlsxJson,
		}
	}
</script>

<style scoped>

#upload-home {
	padding-top: 10px;
}
.top-info {
	margin-left: 10px;
}
.top-info label {
	font-weight: 500;
	font-size: 18px;
	width: 100px;
	color: #1a1e27;
}

.top-info span {
	font-weight: 400;
	font-size: 16px;
}
</style>