<template>
	<div>
		<div class="file-link">
			<label>업로드 방법 : 배치관리> 강사배치현황(게시후) 엑셀 다운로드 후 업로드</label>
		</div>
		<table id="noticelist" class="table">
			<thead class="bg-primary text-white">
				<tr>
					<th>학교코드</th>
					<th>학교생활권</th>
					<th>분야</th>
					<th>교육과정</th>
					<th>학교배정시수</th>
					<th>강사번호</th>
					<th>강사생활권</th>
					<th>배치상태</th>
					<th>배치시수</th>
					<th>배치번호</th>
				</tr>
			</thead>
			<tbody>
			</tbody>
		</table>
	</div>
</template>

<script>
	export default {
		name: 'AssignComponent',
		props: ['list'],
		watch: {
			list: function () {
				window.$.fn.dataTable.ext.errMode = 'none';

				console.log(this.list);
				if(window.$.fn.dataTable.isDataTable('#noticelist')) {
					const table = window.$('#noticelist').dataTable();
					const data = this.list.map((v) => {
						return {
							학교코드: v.__EMPTY_3,
							학교생활권: v.__EMPTY_1,
							분야: v.__EMPTY_4,
							교육과정: v.__EMPTY_6,
							학교배정시수: v.__EMPTY_7,
							강사번호: v.__EMPTY_11,
							강사생활권: v.__EMPTY_9,
							배치상태: v.__EMPTY_13,
							배치시수: v.__EMPTY_14,
							배치번호: v.강사배치현황목록,
						}
					});

					table.fnClearTable();

					if(data.length > 0) {
						table.fnAddData(data);
					}
				} else {
					const data = this.list.map((v) => {
						return {
							학교코드: v.__EMPTY_3,
							학교생활권: v.__EMPTY_1,
							분야: v.__EMPTY_4,
							교육과정: v.__EMPTY_6,
							학교배정시수: v.__EMPTY_7,
							강사번호: v.__EMPTY_11,
							강사생활권: v.__EMPTY_9,
							배치상태: v.__EMPTY_13,
							배치시수: v.__EMPTY_14,
							배치번호: v.강사배치현황목록,
						}
					});

					window.$('#noticelist').dataTable({
						data: data,
						retrive: true,
						columns: [
							{data:'학교코드'},
							{data:'학교생활권'},
							{data:'분야'},
							{data:'교육과정'},
							{data:'학교배정시수'},
							{data:'강사번호'},
							{data:'강사생활권'},
							{data:'배치상태'},
							{data:'배치시수'},
							{data:'배치번호'},
						]
					});
				}
			}
		}
	}
</script>

<style scoped>
.file-link {
	margin-bottom: 20px;
}

.file-link a {
	font-size: 16px;
	font-weight: 400px;
}

.file-link label {
	font-size: 16px;
	font-weight: 400;
	color: #1a1e27;
	margin-right: 30px;
}
#noticelist>thead>tr>th {
	font-size: 0.7vw;
}
</style>