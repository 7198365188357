<template>
	<div id="pnoticehome">
		<div v-if="mode == 'notice'" id="phonenotice">
			<swiper v-if="banners.length > 0"
				:options="swiperOption"
			>
				<swiper-slide v-for="(item) in banners" :key="item.seq">
					<img style="max-width:98.5%;margin-left:1px" :src="item.banner" />
				</swiper-slide>
			</swiper>
			<!-- div v-if="banners.length > 0" class="swiper-pagination"></!-->
			<ul>
				<li v-for="(item,idx) in list" :key="idx">
					<div class="toprow">
						<div class="fixed" v-if="item.fix == 0">
							★
						</div>
						<div class="seq" v-else>
							{{list.length-idx}}
						</div>
						<div class="date">
							{{dateFormat(item.createdAt)}}
						</div>
					</div>
					{{item.subject}}
					<div v-if="item.filename != ''" class="bottomrow">
						<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M15.2202 7.78228L8.45977 14.7258C7.63157 15.5765 6.50828 16.0544 5.33702 16.0544C4.16575 16.0544 3.04246 15.5765 2.21426 14.7258C1.38605 13.8752 0.920769 12.7215 0.920769 11.5185C0.920769 10.3155 1.38605 9.16181 2.21426 8.31117L8.97472 1.36761C9.52685 0.800522 10.2757 0.481934 11.0566 0.481934C11.8374 0.481934 12.5863 0.800522 13.1384 1.36761C13.6905 1.93471 14.0007 2.70385 14.0007 3.50584C14.0007 4.30782 13.6905 5.07697 13.1384 5.64406L6.37058 12.5876C6.09451 12.8712 5.72008 13.0305 5.32966 13.0305C4.93924 13.0305 4.56481 12.8712 4.28874 12.5876C4.01267 12.3041 3.85758 11.9195 3.85758 11.5185C3.85758 11.1175 4.01267 10.7329 4.28874 10.4494L10.5343 4.04228" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>
				</li>
			</ul>
		</div>
		<div v-if="mode == 'form'" id="phonenotice">
			<ul>
				<li v-for="(item,idx) in list" :key="idx">
					<div class="toprow">
						<div class="seq">
							{{list.length-idx}}
						</div>
						<div class="date">
							{{dateFormat(item.createdAt)}}
						</div>
					</div>
					{{item.subject}}
					<div v-if="item.filename != ''" class="bottomrow">
						<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M15.2202 7.78228L8.45977 14.7258C7.63157 15.5765 6.50828 16.0544 5.33702 16.0544C4.16575 16.0544 3.04246 15.5765 2.21426 14.7258C1.38605 13.8752 0.920769 12.7215 0.920769 11.5185C0.920769 10.3155 1.38605 9.16181 2.21426 8.31117L8.97472 1.36761C9.52685 0.800522 10.2757 0.481934 11.0566 0.481934C11.8374 0.481934 12.5863 0.800522 13.1384 1.36761C13.6905 1.93471 14.0007 2.70385 14.0007 3.50584C14.0007 4.30782 13.6905 5.07697 13.1384 5.64406L6.37058 12.5876C6.09451 12.8712 5.72008 13.0305 5.32966 13.0305C4.93924 13.0305 4.56481 12.8712 4.28874 12.5876C4.01267 12.3041 3.85758 11.9195 3.85758 11.5185C3.85758 11.1175 4.01267 10.7329 4.28874 10.4494L10.5343 4.04228" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>
				</li>
			</ul>
		</div>
		<div v-if="mode == 'faq'" id="phonefaq">
			<ul>
				<li v-for="(item,idx) in list" :key="idx" @click="clickFaqItem(idx)">
					<div class="title">
						<div :class="{'left':true, 'margin-down':categoryOn == false}">
							<h1>{{item.subject}}</h1>
							<h3 v-if="categoryOn">{{item.category}}</h3>
						</div>
						<div :class="{'right':true, 'margin-up':categoryOn == false}">
							<svg v-if="item.seq != curSeq" width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.5 1L7.5 7L13.5 1" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
							<svg v-else width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M13.5 7L7.5 1L1.5 7" stroke="#616774" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
						</div>
					</div>
					<div class="content" v-if="item.seq == curSeq">
						<p v-html="item.content"></p>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

	export default {
		name: 'PhoneNoticeHome',
		props: ['list','mode'],
		data() {
			return {
				swiperOption: {
					effect: 'fade',
					//slidesPerView: 'auto',
					spaceBetween: 6, 
					slidesOffsetBefore: 0, 
					slidesOffsetAfter: 0, 
					freeMode: false, 
					centerInsufficientSlides: true, 
					autoplay : {
						delay : 3000,
						disableOnInteraction : false,
					},
					pagination: {
						el: ".swiper-pagination",
						type : 'bullets',
					}
				},
				banners: [],
				categoryOn: false,
				curSeq: -1,
			}
		},
		mounted() {
			console.log('mounted',this.list);
			if(this.list) {
				this.banners =  this.list.filter(x => x.banner != '');
			}
			const self = this;
			this.$nextTick(() => {
				console.log('tick',self.list);
			})
		},
		watch: {
			list: function () {
				if(this.list) {
					this.banners =  this.list.filter(x => x.banner != '');
				}
			}
		},
		methods: {
			dateFormat: function (dt) {
				const date = new Date(dt);

				return date.getFullYear().toString().substring(2,4) + '/' + (date.getMonth()+1).toString() + '/' + date.getDate().toString();
			},
			clickBanner: function (seq) {
				const idx = this.banners.findIndex(x => x.seq === seq);

				this.clickDetail(idx);
			},
			clickDetail: function (idx) {
				this.$store.state.notice.item = this.list[idx];
				this.$store.state.notice.prev = idx > 0 ? this.list[idx-1] : {};
				this.$store.state.notice.next = idx < (this.list.length-1) ? this.list[idx+1] : {};
				this.$router.push({
					name: 'NoticeHomeDetail',
					params: {
						item: this.list[idx],
					}
				});
			},
			clickFaqItem: function (idx) {
				//this.list[idx].active = !this.list[idx].active;
				if(this.curSeq != this.list[idx].seq) {
				this.curSeq = this.list[idx].seq;
				} else {
					this.curSeq = -1;
				}

			}
		},
		components: {
			Swiper,
			SwiperSlide,
		}
	}
</script>

<style scoped>
	#pnoticehome {
		width: 100%;
		height: 100%;
		overflow: scroll;
		scrollbar-width: none;
	}

	#pnoticehome .bottomrow {
		text-align: right;
		margin-left: auto;
		margin-top: auto;
	}

	#pnoticehome::-webkit-scrollbar {
		display: none;
	}

	#pnoticehome .banner {
		display: flex;
		width: 100%;
		height: 241px;
		overflow: hidden;
		padding: 0;
		margin:5px;
	}

	#pnoticehome .banner img {
		width: 100%;
		margin-top: -25%;
	}

	#pnoticehome ul {
		list-style: none;
		text-align: left;
		margin-left: 20px;
		margin-right: 20px;
		padding:0;
	}

	#pnoticehome ul li {
		padding-top:10px;
		padding-bottom: 16px;
		border-bottom: 1px solid #e4e6e8;
		font-size: 16px;
		color: #303641;
	}

	#pnoticehome .toprow {
		display: flex;
		justify-content: space-between;
	}

	#pnoticehome .fixed {
		color: #F05780;
		font-size: 20px;
		margin-top: -5px;
	}

	#pnoticehome .seq {
		color: #106a80;
		font-weight: 500;
		font-size: 13px;
		margin-top: 2px;
	}

	#pnoticehome .date {
		color: #303641;
		font-size: 13px;
		font-weight: 500;
	}

	#phonefaq ul {
		list-style: none;
		padding: 0px 10px;
		margin-top:20px;
		margin-left:5px;
		margin-right:5px;
	}

	#phonefaq ul li {
		padding: 15px 16px;
		background-color: #e4e6e8;
		border-radius: 10px;
		text-align: left;
		min-height: 60px;
		margin-bottom: 20px;
	}

	#phonefaq ul li .title {
		display: flex;
		height: 36px;
		justify-content: space-between;
		align-items: center;
	}

	#phonefaq .left {
		transform: translate(0%,0%)
	}

	#phonefaq .right {
		padding-top:0px;
		margin-top:-7px;
	}

	#phonefaq ul li h1 {
		font-size: 16px;
		font-weight: 500;
		margin:0;
		margin-right: 7px;
	}

	#phonefaq ul li h3 {
		font-size: 11px;
		font-weight: 400;
		padding:0;
		margin:0;
		margin-top: 5px;
	}

	#phonefaq .content {
		margin-top: 16px;
	}

	#phonefaq p {
		word-break: keep-all;
		font-size: 15px;
		font-weight: 400;
	}

	#phonefaq .margin-down {
		margin-top: 7px;
	}

	#phonefaq .margin-up {
		margin-top: -3px;
	}

	.swiper-pagination {
		position:absolute;
		top: 420px;
		left: 50%;
	}

	#phonenotice .swiper-pagination-bullet {
		display: block;
		margin-left:15px !important;
		margin-right:15px !important;
	}

	#phonenotice .swiper-pagination-bullet-active {
		background-color: white !important;
	}
</style>