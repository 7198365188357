<template>
	<div id="chatadmin">
		<div class="chatlist-title">
				<h1>상담목록</h1>
				<svg @click="onTarget" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
					<line x1="0.357422" y1="11.3703" x2="24.382" y2="11.3703" stroke="black" stroke-width="2"/>
					<line x1="13.3711" y1="0.358063" x2="13.3711" y2="24.3826" stroke="black" stroke-width="2"/>
				</svg>
		</div>

		<div class="searchbar">
			<input type="text" v-on:input="onInputChatlist" v-on:keypress.enter="onEnterSearch">
			<svg @click="onEnterSearch" style="margin-top:2px" width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M25 25L19.2 19.2M22.3333 11.6667C22.3333 17.5577 17.5577 22.3333 11.6667 22.3333C5.77563 22.3333 1 17.5577 1 11.6667C1 5.77563 5.77563 1 11.6667 1C17.5577 1 22.3333 5.77563 22.3333 11.6667Z" stroke="#4C2E83" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</div>
		<div class="body">
			<div class="newchat">
				<h1>미확인 상담</h1>
				<ul class="newlist">
					<li v-for="(item) in chatlist.filter(x => { return (searchChatList.length == 0 || x.content.indexOf(searchChatList) >= 0) && x.closedAt == '' })" :key="item.code" @click="clickItem(item)">
						<div class="chat">
							<div class="sender">
								[{{item.field}}] {{item.name}}
							</div>
							<div class="content">
								{{item.content}}
							</div>
						</div>
						<div class="date">
							{{dateFormat(item.messageAt)}}
						</div>
					</li>
				</ul>
			</div>
			<div class="readchat">
				<h1>확인한 상담</h1>
				<ul class="readlist">
					<li v-for="(item) in chatlist.filter(x => { return (searchChatList.length == 0 || x.content.indexOf(searchChatList) >= 0) && x.closedAt != '' && (x.code != '' || x.schoolCode != '')})" :key="item.code" @click="clickItem(item)">
						<div class="chat">
							<div class="sender">
								[{{item.field}}] {{item.name}}
							</div>
							<div class="content">
								{{item.content}}
							</div>
						</div>
						<div class="date">
							{{dateFormat(item.messageAt)}}
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ChatListComponent',
		data() {
			return {
				chatlist: [],
				/*newlist: [],
				readlist: [],*/
				isSearchMode: false,
				searchText: '',
				searchChatList: '',
			}
		},
		mounted() {
			const self = this;

			//this.newlist = [];
			//this.readlist = [];


			this.$event.$emit('spinner', true);

			/*this.$event.$on('chatClose', function (item) {

			});*/

			this.$http.get('https://asai.sacesapp.com/v1/Chat/hey')
			.then((res) => {
				this.$event.$emit('spinner', false);

				if(res.data.success == true) {
					const chats = res.data.chats;

					self.chatlist = chats;

					/*self.newlist = chats.filter(x => {
						return x.staffSeq == '0'
					});

					self.readlist = chats.filter(x => {
						return x.staffSeq != '0'
					})*/
				}
			})
		},
		methods: {
			onTarget: function () {
				this.$emit('target');
			},
			onEnterSearch: function (e) {
				this.searchChatList = '';
				e.target.value = '';
			},
			onInputChatlist: function (e) {
				this.searchChatList = e.target.value.trim();
			},
			searchOpen: function () {
				if(this.isSearchMode == false) {
					/*if(this.$store.state.chatPollingHandler >= 0) {
						clearInterval(this.$store.state.chatPollingHandler);
						this.$store.state.chatPollingHandler = -1;
					}*/

					this.isSearchMode = true;

					/*this.backupList = [];
					this.chatlist.forEach(x => {
						this.backupList.push(x);
					});*/
				}
			},
			searchClose: function () {
				this.isSearchMode = false;
				/*this.chatlist = this.backupList;

				self.loadChat((!self.chatlist || self.chatlist.length == 0) ? 0 : self.chatlist[self.chatlist.length-1].seq, true);*/
			},
			search: function (t) {
				this.searchText = t;
				/*this.chatlist = this.backupList.filter(x => {
					return x.content.indexOf(t) >= 0;
				});*/
			},
			utcToKor: function(date) {
				let dt = new Date(date);
				
				dt.setTime(dt.getTime() + (9*60*60*1000));
                return dt;
            },
			dateFormat: function (srcDt) {
				const today = new Date();
				const timeValue = srcDt ? new Date(srcDt) : today;

				const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
				if (betweenTime < 1) {
					return `방금 전`;
				}

				if (betweenTime < 60) {
					return `${betweenTime}분 전`;
				}

				const betweenTimeHour = Math.floor(betweenTime / 60);
				if (betweenTimeHour < 24) {
					return `${betweenTimeHour}시간 전`;
				}

				const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
				if (betweenTimeDay <= 30) {
					return `${betweenTimeDay}일 전`;
				}

				if(Math.floor(betweenTimeDay/30) >= 12) {
					return `${Math.floor(betweenTimeDay / 30 / 12)}년 전`
				}

				if(Math.floor(betweenTimeDay/30) >= 1) {
					return `${Math.floor(betweenTimeDay/30)}개월 전`
				}

				const year = timeValue.getFullYear().toString().substring(2);
				const month = ((timeValue.getMonth() + 1) < 10 ? '0' : '') + (timeValue.getMonth() + 1).toString();
				const date = (timeValue.getDate() < 10 ? '0' : '') + timeValue.getDate().toString();

				return year + '.' + month + '.' + date;
			},
			dateFormtat: function (date) {
				let dt = new Date(date);
				const now = new Date();
				let t = '';

				dt.setHours(dt.getHours() + 9);

				//let calc = Math.abs(now.getTime() - dt.getTime());
				//calc = Math.ceil(calc / (1000 * 3600 * 24)) - 1;

				let m = now.getMonth() - dt.getMonth();
				const y = now.getFullYear() - dt.getFullYear();
				let d = now.getDate() - dt.getDate();


				console.log(dt, y, m, d);
				if(y > 1) {
					return y.toString() + '년전';
				}

				if(m < -1) {
					m = (now.getMonth()+12)-dt.getMonth();
				}

				if(m > 1) {
					return m.toString() + '개월전';
				}

				if(d < 0) {
					m = (now.getDate()+30)-dt.getDate(); 
				}

				if(d > 0) {
					return d.toString() + '일전';
				}

				let hour = dt.getHours();
				let min = dt.getMinutes();

				if(hour < 10) {
					hour = '0' + hour.toString();
				}

				if(min < 10) {
					min = '0' + min.toString();
				}
				t = hour + ':' + min;
				
				return t;
			},
			clickItem: function (item) {
				//const list = this.chatlist; //group === 0 ? this.chatlist : this.readlist;

				//const item = list.find((x) => { return x.code === code });
				this.$event.$emit('chat', item);
			},
			clickWrite: function () {
				this.$router.push('/chat/admin/assign');
			}
		},
		components: {
		}	
	}
</script>

<style scoped>
	#chatadmin {
		text-align: left;
	}

	#chatadmin>.searchbar {
		width: 100%;
		height: 43px;
		padding:5px 15px;
		background-color: white;
		display:flex;
		justify-content: space-between;
		border:1px solid #e3e5e5;
		border-radius: 48px;
		margin-top: 25px;
		margin-bottom: 25px;
	}

	#chatadmin>.searchbar>input {
		border: none;
		width: 80%;
	}

	#chatadmin>.searchbar>input::placeholder {
		font-size: 14px;
		font-weight: 400;
		color: #919fb6;
	}

	#chatadmin newlist {
		min-height:30%;
		height: 30%;
	}

	#chatadmin>.chatlist-title {
		display: flex;
		justify-content: space-between;
	}

	#chatadmin>div>h1 {
		font-size: 24px;
		font-weight: 400;
		color: black;
	}

	#chatadmin .body {
		padding: 10px 0px;
		overflow: auto;
		height: calc(100vh - 250px);
	}

	#chatadmin>.body h1 {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 12px;
		color: #1a1e27;
	}

	#chatadmin .newchat {
		/*padding-bottom: 10px;*/
	}

	#chatadmin .readchat {
		padding-top: 15px;
		border-top: 1px solid #e3e5e5;
	}

	#chatadmin ul {
		list-style: none;
		padding:0;
		margin:0;
	}

	#chatadmin ul li {
		display: flex;
		position: relative;
		height: 60px;
		padding-top: 6px;
	}

	#chatadmin .chat {
		text-align: left;
		width: 100%;
	}

	#chatadmin .chat .sender {
		font-weight: 500;
		font-size: 15px;
		color: #1a1e27;
	}

	#chatadmin .chat .content {
		font-weight: 400;
		font-size: 14px;
		color: #616774;
		width: 100%;
		height: 20px;
		overflow: hidden;
	}

	#chatadmin .date {
		text-align: right;
		width: 25%;
		color: #778192;
		font-size: 13px;
	}

	img {
		display: none;
		width: 34px;
		height: 34px;
		border-radius:34px;
		margin-right: 8px;
	}
</style>