<template>
	<div>
		<top-menu :menu="topmenu" :curPath="this.$route.path"></top-menu>

		<div class="container-fluid">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FaqPage',
		data() {
			return {
				topmenu: []
			}
		},
		mounted() {
			if(this.$route.path == '/faq') {
				
				this.$router.push('/faq/home');
			}
		}
	}
</script>

<style scoped>

</style>