<template>
	<div id="faq-home">
		<top-menu ></top-menu>

		<div class="row saveRow">
			<button class="btn btn-primary" @click="clickSave">저장하기</button>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<table id="noticelist" class="table" style="width:100%">
							<thead class="bg-primary text-white">
								<tr>
									<th>번호</th>
									<th>이메일주소</th>
									<th>회원유형</th>
									<th>학교명</th>
									<th>이름</th>
									<th>연락처</th>
									<th>이름검증</th>
									<th>연락처검증</th>
									<th>승인상태</th>
									<th>승인</th>
									<th>반려</th>
									<th>삭제</th>
								</tr>
							</thead>
							<tbody>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div id="check-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="danger-header-modalLabel" aria-hidden="false">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-danger">
										<h4 class="modal-title" id="danger-header-modalLabel">알림</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
										<p>이미 다른 상태가 선택되어 있습니다</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal">닫 기</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->

		<!-- Primary Header Modal -->
		<div id="acceptok-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="primary-header-modalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-primary">
										<h4 class="modal-title" id="primary-header-modalLabel">알림
										</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
										<h5 class="mt-0">회원승인</h5>
										<p>{{processMessage}}</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal">Close</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->

		<div id="delete-confirm-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="danger-header-modalLabel" aria-hidden="false">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-danger">
										<h4 class="modal-title" id="danger-header-modalLabel">삭제하기</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
										<p>삭제 대상이 포함되어 있습니다.<br />계속하시겠습니까?</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal">취소하기</button>
										<button type="button" class="btn btn-danger" @click="acceptProcess">삭제하기</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->
	</div>
</template>

<script>
	export default {
		name: 'UserAcceptPage',
		data() {
			return {
				category: '예술강사',
				categoryMenu: ['예술강사','운영학교','운영기관'],
				list: [],
				fields: ['배너여부'],
				current: {
					seq: '-',
					email: '-',
					type: '-',
					schoolName: '-',
					name: '-',
					phone: '-',
					emailCheck: '-',
					nameCheck: '-',
					phoneCheck: '-',
					accept: '-',
				},
				processMessage: '',
			}
		},
		mounted() {
			if(this.$route.params.category) {
				this.category = this.$route.params.category;
			}

			this.loadData();
		},
		computed: {
		},
		methods: {
			clickSave: function () {
				let hasDelete = this.list.filter(x => x.accept == 'D').length > 0 ? true : false;
				let hasReject = this.list.filter(x => x.accept == 'N').length > 0 ? true : false;
				let hasAccept = this.list.filter(x => x.accept == 'Y').length > 0 ? true : false;

				let msg = '';

				if(hasDelete) {
					if(hasAccept) {
						msg += '승인';
					}

					if(hasReject) {
						if(msg != '') {
							msg += ','
						}
						msg += '반려'
					}

					if(msg != '') {
						msg += ' 및 ';
					}

					msg += '삭제 처리가 완료되었습니다';
				} else {
					msg = '변경된 정보가 저장되었습니다';
				}

				this.processMessage = msg;

				if(hasDelete) {
					window.$('#delete-confirm-modal').modal('show');
					//삭제의 경우 모달에서 삭제를 누르면 acceptProcess 를 호출하게 되므로 여기서 부르지 않음.
				} else {
					this.acceptProcess();
				}
			},
			acceptProcess: function ()
			{
				window.$('#delete-confirm-modal').modal('hide');
				const accept = this.list.filter(x => x.accept != '-');

				this.$http.post('https://asai.sacesapp.com/v1/User/acptusr', accept)
				.then((res) => {
					if(res.data.success) {
						window.$('#acceptok-modal').modal('show');
						this.loadData();
					}
				})
			},
			clickCheck: function (seq,row,col) {
				console.log(seq,row,col);
			},
			loadData: function () {
				this.$http.post('https://asai.sacesapp.com/v1/User/lwait?pp=lkjdfhdfioh!')
				.then((res) => {
					if(res.data.success == true) {
						this.list = res.data.list;
						window.$.fn.dataTable.ext.errMode = 'none';
						if(window.$.fn.dataTable.isDataTable('#noticelist')) {
							const dt = window.$('#noticelist').dataTable();
							let idx = 1;
							const data = this.list.map(function (x) {
									var r = {
										seq: idx++,
										email: x.email,
										name: x.name,
										type: x.type,
										schoolName: x.schoolName,
										phone: x.phone,
										//hasEmail: x.email == x.hasEmail ? '<svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.53033" y1="9.46967" x2="9.53033" y2="18.4697" stroke="#2400FF" stroke-width="1.5"/><line x1="8.46967" y1="18.4697" x2="25.4697" y2="1.46967" stroke="#2400FF" stroke-width="1.5"/></svg>' : '<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.353553" y1="0.646447" x2="18.3536" y2="18.6464" stroke="#FF0000"/><line x1="18.3536" y1="1.35355" x2="0.353553" y2="19.3536" stroke="#FF0000"/></svg>',
										hasName: x.email == x.hasEmail && x.name == x.hasName ? '<svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.53033" y1="9.46967" x2="9.53033" y2="18.4697" stroke="#2400FF" stroke-width="1.5"/><line x1="8.46967" y1="18.4697" x2="25.4697" y2="1.46967" stroke="#2400FF" stroke-width="1.5"/></svg>' : '<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.353553" y1="0.646447" x2="18.3536" y2="18.6464" stroke="#FF0000"/><line x1="18.3536" y1="1.35355" x2="0.353553" y2="19.3536" stroke="#FF0000"/></svg>',
										hasPhone: x.email == x.hasEmail && x.phone == x.hasPhone ? '<svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.53033" y1="9.46967" x2="9.53033" y2="18.4697" stroke="#2400FF" stroke-width="1.5"/><line x1="8.46967" y1="18.4697" x2="25.4697" y2="1.46967" stroke="#2400FF" stroke-width="1.5"/></svg>' : '<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.353553" y1="0.646447" x2="18.3536" y2="18.6464" stroke="#FF0000"/><line x1="18.3536" y1="1.35355" x2="0.353553" y2="19.3536" stroke="#FF0000"/></svg>',
										userStatus: x.accept && x.accept == 'N' ? '반려' : '승인대기',
										accept: x.accept,
										reject: '',
									}

									return r;
								});

							dt.fnClearTable();

							if(data.length > 0) {
								dt.fnAddData(data);
							}
						} else {
							let idx = 1;
							window.$('#noticelist').dataTable({
								data: this.list.map(function (x) {
									var r = {
										seq: idx++,
										email: x.email,
										name: x.name,
										type: x.type,
										schoolName: x.schoolName,
										phone: x.phone,
										//hasEmail: x.email == x.hasEmail ? '<svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.53033" y1="9.46967" x2="9.53033" y2="18.4697" stroke="#2400FF" stroke-width="1.5"/><line x1="8.46967" y1="18.4697" x2="25.4697" y2="1.46967" stroke="#2400FF" stroke-width="1.5"/></svg>' : '<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.353553" y1="0.646447" x2="18.3536" y2="18.6464" stroke="#FF0000"/><line x1="18.3536" y1="1.35355" x2="0.353553" y2="19.3536" stroke="#FF0000"/></svg>',
										hasName: x.name == x.hasName ? '<svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.53033" y1="9.46967" x2="9.53033" y2="18.4697" stroke="#2400FF" stroke-width="1.5"/><line x1="8.46967" y1="18.4697" x2="25.4697" y2="1.46967" stroke="#2400FF" stroke-width="1.5"/></svg>' : '<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.353553" y1="0.646447" x2="18.3536" y2="18.6464" stroke="#FF0000"/><line x1="18.3536" y1="1.35355" x2="0.353553" y2="19.3536" stroke="#FF0000"/></svg>',
										hasPhone: x.phone == x.hasPhone ? '<svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.53033" y1="9.46967" x2="9.53033" y2="18.4697" stroke="#2400FF" stroke-width="1.5"/><line x1="8.46967" y1="18.4697" x2="25.4697" y2="1.46967" stroke="#2400FF" stroke-width="1.5"/></svg>' : '<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.353553" y1="0.646447" x2="18.3536" y2="18.6464" stroke="#FF0000"/><line x1="18.3536" y1="1.35355" x2="0.353553" y2="19.3536" stroke="#FF0000"/></svg>',
										userStatus: x.accept && x.accept == 'N' ? '반려' : '승인대기',
										accept: x.accept,
										reject: '',
									}

									return r;
								}),
								retrive: true,
								columns: [
									{data: 'seq'},
									{data: 'email'},
									{data: 'type'},
									{data: 'schoolName'},
									{data: 'name'},
									{data: 'phone'},
									//{data: 'hasEmail', align: 'center'},
									{data: 'hasName'},
									{data: 'hasPhone'},
									{data: 'userStatus', align: 'center'},
									{data: 'accept'},
									{date: ''},
									{date: ''},
								],
								columnDefs: [
									{ 
										className: 'text-center', 
										targets: [6, 7, 8], 
										sesearchable: false, 
										orderable: false, 
									},
									{ 
										targets: [9, 10, 11],
										searchable: false,
										orderable: false,
										classNane: 'text-center',
										render: function(data, type, full, meta) {
											let checked = meta.col == 10 && full.accept == 'N' ? 'checked' : '';
											return `<input type="checkbox" style="width:20px;height:20px;" class="accept-checkbox" data-row=${meta.row} data-col=${meta.col} ${checked} >`;
										}
									},
								]
							});

							const self = this;
							window.$('#noticelist').on('change', 'tbody tr td input', function (e) {
								const row = parseInt(e.target.dataset.row);
								const col = parseInt(e.target.dataset.col);

								const siblingCheckbox = window.document.querySelectorAll(`[data-row="${row}"`);
								siblingCheckbox.forEach(x => {
									if(col != x.dataset.col) {
										x.checked = false;
									}
								})

								switch(col) {
									case 9 :
										self.list[row].accept = e.target.checked ? 'Y' : '-';
										break;
									case 10 :
										self.list[row].accept = e.target.checked ? 'N' : '-';
										break;
									case 11 :
										self.list[row].accept = e.target.checked ? 'D' : '-';
								}
							})
						}
					}
				});
			},
		},
		components: {
		}
	}
</script>

<style scoped>
	.saveRow {
		box-sizing: border-box;
		padding: 20px 50px;
		justify-content: right;
	}

	.saveRow>button {
		width:91px;
		height:40px;
		padding-top:7px;
	}

	.curTable {
		width: 85%;
		margin: auto;
	}

	.curTable>thead>tr>th {
		text-align: center;
		border:1px solid black;
	}

	.curTable>tbody>tr>td {
		text-align: center;
		border:1px solid black;
	}
</style>