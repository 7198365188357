import Vue from 'vue'
import VueRouter from 'vue-router'

import Dashboard from '../pages/dashboard'

import Notice from '../pages/notice'
import NoticeHome from '../pages/notice/home'
import NoticeHomeView from '../pages/notice/homeview'
import NoticeHomeWrite from '../pages/notice/homewrite'
import NoticeForm from '../pages/notice/form'
import NoticeFormView from '../pages/notice/formview'
import NoticeFormWrite from '../pages/notice/formwrite'

import FAQ from '../pages/faq'
import FaqHome from '../pages/faq/home'
import FaqView from '../pages/faq/faqview'
import FaqWrite from '../pages/faq/faqwrite'

import Chat from '../pages/chat'
import Dataview from '../pages/dataview'
import Upload from '../pages/upload'
import User from '../pages/user'
import Useraccept from '../pages/useraccept'
import Regist from '../pages/regist'

import Login from '../pages/login'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/notice',
    name: 'Notice',
    component: Notice,
    children: [
      {
        path: '/notice/home',
        name: 'NoticeHome',
        component: NoticeHome,
      },
      {
        path: '/notice/homeview',
        name: 'NoticeHomeView',
        component: NoticeHomeView,
      },
      {
        path: '/notice/homewrite',
        name: 'NoticeHomeWrite',
        component: NoticeHomeWrite,
      },
      {
        path: '/notice/form',
        name: 'NoticeForm',
        component: NoticeForm,
      },
      {
        path: '/notice/formview',
        name: 'NoticeFormView',
        component: NoticeFormView,
      },
      {
        path: '/notice/fromwrite',
        name: 'NoticeFormWrite',
        component: NoticeFormWrite,
      }
    ]
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
    children: [
      {
        path: '/faq/home',
        name: 'FaqHome',
        component: FaqHome,
      },
      {
        path: '/faq/faqview',
        name: 'FaqView',
        component: FaqView,
      },
      {
        path: '/faq/faqwrite',
        name: 'FaqWrite',
        component: FaqWrite,
      }
    ]
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat
  },
  {
    path: '/dataview',
    name: 'Dataview',
    component: Dataview
  },
  {
    path: '/upload',
    name: 'Upload',
    component: Upload
  },
  {
    path: '/user',
    name: 'User',
    component: User
  },
  {
    path: '/useraccept',
    name: 'Useraccept',
    component: Useraccept
  },
  {
    path: '/regist',
    name: 'Regist',
    component: Regist
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
