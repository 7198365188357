<template>
	<div>
		<div class="file-link">
			<label>업로드 방법 : 교육활동관리 > 출강결과확인 엑셀 다운로드 후 업로드</label>
		</div>
		<table id="noticelist" class="table">
			<thead class="bg-primary text-white">
				<tr>
					<th>학교코드</th>
					<th>분야</th>
					<th>교육과정</th>
					<th>강사번호</th>
					<th>수업일</th>
					<th>수업교시</th>
					<th>수업시수</th>
					<th>출강결과등록일</th>
					<th>학교교사처리일</th>
				</tr>
			</thead>
			<tbody>
			</tbody>
		</table>
	</div>
</template>

<script>
	export default {
		name: 'TeachComponent',
		props: ['list'],
		watch: {
			list: function () {
				window.$.fn.dataTable.ext.errMode = 'none';

				if(window.$.fn.dataTable.isDataTable('#noticelist')) {
					const table = window.$('#noticelist').dataTable();
					const data = this.list.map((v) => {
						return {
							학교코드: v.__EMPTY_1,
							분야: v.__EMPTY_2,
							교육과정: v.__EMPTY_3,
							강사번호: v.__EMPTY_6,
							수업일: v.__EMPTY_7,
							수업교시: v.__EMPTY_10,
							수업시수: v.__EMPTY_9,
							출강결과등록일: v.__EMPTY_11,
							학교교사처리일: v.__EMPTY_14,
						}
					}).splice(1);

					table.fnClearTable();

					if(data.length > 0) {
						table.fnAddData(data);
					}
				} else {
					const data = this.list.map((v) => {
						return {
							학교코드: v.__EMPTY_1,
							분야: v.__EMPTY_2,
							교육과정: v.__EMPTY_3,
							강사번호: v.__EMPTY_6,
							수업일: v.__EMPTY_7,
							수업교시: v.__EMPTY_10,
							수업시수: v.__EMPTY_9,
							출강결과등록일: v.__EMPTY_11,
							학교교사처리일: v.__EMPTY_14,
						}
					}).splice(1);

					window.$('#noticelist').dataTable({
						data: data,
						retrive: true,
						columns: [
							{data:'학교코드'},
							{data:'분야'},
							{data:'교육과정'},
							{data:'강사번호'},
							{data:'수업일'},
							{data:'수업교시'},
							{data:'수업시수'},
							{data:'출강결과등록일'},
							{data:'학교교사처리일'},
						]
					});
				}
			}
		}
	}
</script>

<style scoped>
.file-link {
	margin-bottom: 20px;
}

.file-link a {
	font-size: 16px;
	font-weight: 400px;
}

.file-link label {
	font-size: 16px;
	font-weight: 400;
	color: #1a1e27;
	margin-right: 30px;
}

#noticelist>thead>tr>th {
	font-size: 0.7vw;
}
</style>