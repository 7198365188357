<template>
	<div id="faq-home">
		<top-menu ></top-menu>

		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-lg-2"></div>
							<div class="col-lg-4">
								<h1>회원추가</h1>
								<form class="mt-4">
									<h2>&nbsp;</h2>
									<div class="form-group">
										<label class="form-control-label" for="email">이메일주소</label>
										<input type="text" class="form-control" id="email" @blur="onEmail" v-model="newUser.email">
									</div>
									<div class="form-group">
										<div class="input-group-title">
											<label for="password">비밀번호</label>
											<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
												<g clip-path="url(#clip0_5520_2329)">
												<path d="M5.5 7.45577V5.50022M5.5 3.54466H5.50489M10.3889 5.50022C10.3889 8.20028 8.20006 10.3891 5.5 10.3891C2.79994 10.3891 0.611115 8.20028 0.611115 5.50022C0.611115 2.80016 2.79994 0.611328 5.5 0.611328C8.20006 0.611328 10.3889 2.80016 10.3889 5.50022Z" stroke="#4C2E83" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												</g>
												<defs>
												<clipPath id="clip0_5520_2329">
												<rect width="11" height="11" fill="white"/>
												</clipPath>
												</defs>
											</svg>

											<span class="invalidText">영문자+숫자+특수문자 조합 8자 이상 필수</span>
										</div>
										<input type="password" :class="{'form-control':true, 'invalid-border':invalidPassword}" id="password" @blur="onLeavePassword" ref="password" v-model="newUser.password">
									</div>
									<div class="form-group">
										<label class="form-control-label" for="passcheck">비밀번호재입력</label>
										<input type="password" :class="{'form-control':true, 'invalid-border':invalidPasswordCheck}" id="passcheck" @blur="onLeavePasswordCheck" ref="passwordCheck" v-model="newUser.passwordCheck">
									</div>
									<div class="form-group">
										<label class="form-control-label" for="type">회원유형</label>
										<select class="custom-select mr-sm-2" id="type" ref="type" v-model="newUser.type">
											<option value="" selected>선택하세요</option>
											<option value="운영기관">운영기관</option>
											<option value="예술강사">예술강사</option>
											<option value="운영학교">운영학교</option>
										</select>
									</div>
									<div class="form-group">
										<label class="form-control-label" for="school">학교명</label>
										<input type="text" class="form-control" id="school" ref="school" v-model="newUser.schoolName">
									</div>
									<div class="form-group">
										<label class="form-control-label" for="name">이름</label>
										<input type="text" class="form-control" id="name" v-model="newUser.name">
									</div>
									<div class="form-group">
										<label class="form-control-label" for="phone">연락처</label>
										<input type="text" :class="{'form-control':true,'invalid-border':invalidPhone}" ref="phone" id="phone" maxlength="13" @blur="onLeavePhone" v-model="newUser.phone">
									</div>
								</form>
							</div>
							<div class="col-lg-4">
								<h1>&nbsp;</h1>
								<form class="mt-4">
									<h2>선택입력(운영기관만 해당)</h2>
									<div class="form-group">
										<label class="form-control-label gray-color" for="part">소속</label>
										<input type="text" class="form-control" id="part" v-model="newUser.part">
									</div>
									<div class="form-group">
										<label class="form-control-label gray-color" for="position">직급</label>
										<input type="text" class="form-control" id="position" v-model="newUser.position">
									</div>
									<div class="form-group">
										<label class="form-control-label gray-color" for="incharge">담당</label>
										<input type="text" class="form-control" id="incharge" v-model="newUser.incharge">
									</div>
									<div class="form-group">
										<label class="form-control-label gray-color" for="emergency">비상연락망</label>
										<input type="text" maxlength="13" class="form-control" id="emergency" @blur="onLeaveEmergency" v-model="newUser.emergency">
									</div>
								</form>
							</div>
							<div class="col-lg-2">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row saveRow">
			<button class="btn btn-primary" @click="clickSave">저장하기</button>
		</div>
		<!-- div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<table id="noticelist" class="table" style="width:100%">
							<thead class="bg-primary text-white">
								<tr>
									<th>번호</th>
									<th>이메일주소</th>
									<th>회원유형</th>
									<th>학교명</th>
									<th>이름</th>
									<th>연락처</th>
									<th>Aschool 이메일유무</th>
									<th>Aschool 이메일・이름 일치</th>
									<th>Aschool 이메일・연락처 일치</th>
									<th>승인</th>
									<th>반려</th>
								</tr>
							</thead>
							<tbody>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</!-->

		<!-- Primary Header Modal -->
		<div id="ok-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="primary-header-modalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-primary">
										<h4 class="modal-title" id="primary-header-modalLabel">알림
										</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
										<h5 class="mt-0">회원추가</h5>
										<p>회원추가가 완료되었습니다</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal">Close</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->

		<!-- Danger Header Modal -->
		<div id="error-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="danger-header-modalLabel" aria-hidden="false">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-danger">
										<h4 class="modal-title" id="danger-header-modalLabel">오류</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
										<h5 class="mt-0">입력내용오류</h5>
										<p>{{errMessage}}</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal">닫 기</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->
	</div>
</template>

<script>
	export default {
		name: 'RegistPage',
		data() {
			return {
				category: '예술강사',
				categoryMenu: ['예술강사','운영학교','운영기관'],
				list: [],
				fields: ['배너여부'],
				newUser: {
					email: '',
					password: '',
					passwordCheck: '',
					name: '',
					phone: '',
					type: '',
					schoolCode: '',
					schoolName: '',
					code: '',
					part: '',
					position: '',
					incharge: '',
					emergency: '',
				},
				
				current: {
					seq: '-',
					email: '-',
					type: '-',
					schoolName: '-',
					name: '-',
					phone: '-',
					emailCheck: '-',
					nameCheck: '-',
					phoneCheck: '-',
					accept: '-',
				},
				errMessage: '',

				invalidPassword: false,
				invalidPasswordCheck: false,
				invalidPhone: false,
			}
		},
		mounted() {
		},
		methods: {
			validate: function (phone) {
				const regPhone = /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{3})$/;
				return regPhone.test(phone);
			},
			onEnterPasswordCheck: function () {
			},
			onLeavePasswordCheck: function () {
				/*if(this.newUser.password != this.newUser.passwordCheck) {
					this.invalidPasswordCheck = true;
					if(this.newUser.passwordCheck.trim() != '') {
						//this.$refs.passwordCheck.focus();
					}
				} else {
					this.invalidPasswordCheck = false;
				}*/
			},
			onEnterPassword: function () {
			},
			onLeavePassword: function () {
			},
			validatePassword: function () {
				const reg_pwd = /^.*(?=.{8,20})(?=.*[0-9])(?=.*[a-zA-Z]).*$/;
				const special = this.newUser.password.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

				if(this.newUser.password.trim() == '') {
					this.invalidPassword = true;
					this.$refs.password.focus();
					return false;
				}

				if(special < 0) {
					this.invalidPassword = true;
					this.$refs.password.focus();
					return false;
				}

				if(!reg_pwd.test(this.newUser.password)) {
					this.invalidPassword = true;
					this.$refs.password.focus();
					return false;
				}

				return true;
			},
			onLeavePhone: function () {
				this.newUser.phone = this.newUser.phone.replace(/[^0-9]/g,'');
			},
			onLeaveEmergency: function () {
				this.newUser.emergency = this.newUser.emergency.replace(/[^0-9]/g,'');
			},
			validatePhone: function () {
				let min = 0;
				switch(this.newUser.phone.substr(0,2)) {
					case '02':
						min = 9;
						break;
					case '01':
						min = 11;
						break;
					default:
						min = 10;
						break;
				}

				return min;
			},
			autoHypenPhone: function (str){
				str = str.replace(/[^0-9]/g, '');
				var tmp = '';
				if( str.length < 4){
						return str;
				}else if(str.length < 7){
						tmp += str.substr(0, 3);
						tmp += '-';
						tmp += str.substr(3);
						return tmp;
				}else if(str.length < 11){
						tmp += str.substr(0, 3);
						tmp += '-';
						tmp += str.substr(3, 3);
						tmp += '-';
						tmp += str.substr(6);
						return tmp;
				}else{              
						tmp += str.substr(0, 3);
						tmp += '-';
						tmp += str.substr(3, 4);
						tmp += '-';
						tmp += str.substr(7);
						return tmp;
				}
			},
			onKeypressPhone: function () {
				//e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");
				//e.target.value = this.autoHypenPhone(e.target.value)
			},
			onKeypressEmergency: function () {
				//e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");
				//e.target.value = this.autoHypenPhone(e.target.value)
			},
			onEmail: function () {
				this.$http.post('https://asai.sacesapp.com/v1/User/chke?email='+this.newUser.email.trim())
				.then((res) => {
					if(res.data.success) {
						this.newUser.code = res.data.user.code;
						this.newUser.schoolCode = res.data.user.schoolCode;
						this.newUser.schoolName = res.data.user.schoolName;


						if(this.newUser.schoolCode == '') {
							this.newUser.type = '예술강사';
							this.$ref.school.enable = false;
						} else {
							this.newUser.type = '운영학교';
						}
					} else {
						this.newUser.type = '운영기관';
						this.$refs.type.enable = false;
						this.$refs.school.enable = false;
					}
				})
			},
			clickSave: function () {
				this.newUser.email = this.newUser.email.trim();
				this.newUser.password = this.newUser.password.trim();
				this.newUser.passwordCheck = this.newUser.passwordCheck.trim();

				if(this.newUser.email == '') {
					this.errMessage = ('이메일을 확인해 주세요');
					window.$('#error-modal').modal('show');
					return;
				}

				if(this.validatePassword() == false) {
					this.$refs.password.focus();
					this.errMessage = ('비밀번호를 확인해 주세요');
					window.$('#error-modal').modal('show');
					return;
				} else {
					this.invalidPassword = false;
				}
				
				if(this.newUser.password === '') {
					this.$refs.password.focus();
					this.errMessage = ('비밀번호를 확인해 주세요');
					window.$('#error-modal').modal('show');
					return;
				}

				if(this.newUser.passwordCheck === '') {
					this.invalidPasswordCheck = true;
					this.$refs.passwordCheck.focus();
					this.errMessage = ('비밀번호를 확인해 주세요');
					window.$('#error-modal').modal('show');
					return;
				}

				if(this.newUser.password !== this.newUser.passwordCheck) {
					//this.newUser.password = '';
					//this.newUser.passwordCheck = '';
					this.invalidPasswordCheck = true;
					this.$refs.passwordCheck.focus();
					this.errMessage = ('비밀번호를 확인해 주세요');
					window.$('#error-modal').modal('show');
					return;
				} else {
					this.invalidPasswordCheck = false;
				}

				this.newUser.name = this.newUser.name.trim();
				if(this.newUser.name == '') {
					this.errMessage = ('이름을 확인해 주세요');
					window.$('#error-modal').modal('show');
					return;
				}

				this.newUser.phone = this.newUser.phone.trim();
				if(this.newUser.phone.length < this.validatePhone()) {
					this.errMessage = ('연락처를 확인해 주세요');
					window.$('#error-modal').modal('show');
					return;
				}

				if(this.newUser.type == '운영기관') {
					this.newUser.part = this.newUser.part.trim();
					if(this.newUser.part == '') {
						this.errMessage = ('소속을 확인해 주세요');
						window.$('#error-modal').modal('show');
						return;
					}

					this.newUser.position = this.newUser.position.trim();
					if(this.newUser.position == '') {
						this.errMessage = ('직급을 확인해 주세요');
						window.$('#error-modal').modal('show');
						return;
					}

					this.newUser.incharge = this.newUser.incharge.trim();
					if(this.newUser.incharge == '') {
						this.errMessage = ('담당을 확인해 주세요');
						window.$('#error-modal').modal('show');
						return;
					}

					this.newUser.emergency = this.newUser.emergency.trim();
					if(this.newUser.emergency == '') {
						this.errMessage = ('비상연락망을 확인해 주세요');
						window.$('#error-modal').modal('show');
						return;
					}
				}

				this.$http.post('https://asai.sacesapp.com/v1/User/regist',
				{
					email: this.newUser.email,
					password: this.newUser.password,
					name: this.newUser.name,
					type: this.newUser.type,
					phone: this.newUser.phone,
					schoolCode: this.newUser.schoolCode,
					code: this.newUser.code,
					push: 'Y',
					part: this.newUser.part,
					position: this.newUser.position,
					incharge: this.newUser.incharge,
					emergency: this.newUser.emergency,
				})
				.then((res) => {
					if(res.data.success) {
						this.newUser.email = '';
						this.newUser.password = '';
						this.newUser.passwordCheck = '';
						this.newUser.name = '';
						this.newUser.type = '';
						this.newUser.phone = '';
						this.newUser.schoolName = '';
						this.newUser.schoolCode = '';
						this.newUser.code = '';
						this.newUser.part = '';
						this.newUser.position = '';
						this.newUser.incharge = '';
						this.newUser.emergency = '';
						window.$('#ok-modal').modal('show');
					} else {
						this.errMessage = '이미 등록된 이메일 또는 네트워크오류입니다';
						window.$('#error-modal').modal('show');
					}
				})
			}
		},
		components: {
		}
	}
</script>

<style scoped>
	.saveRow {
		box-sizing: border-box;
		padding: 20px 50px;
		justify-content: right;
	}

	.saveRow>button {
		width:91px;
		height:40px;
		padding-top:7px;
	}

	.curTable {
		width: 85%;
		margin: auto;
	}

	.curTable>thead>tr>th {
		text-align: center;
		border:1px solid black;
	}

	.curTable>tbody>tr>td {
		text-align: center;
		border:1px solid black;
	}

	label {
		color: #303641 !important;
	}

	h1 {
		font-size:24px;
		font-weight:400;
		color:black;
		font-family:'Noto Sans KR';
	}

	h2 {
		font-size:18px;
		font-weight:400;
		color:#919fb6;
		font-family:'Noto Sans KR';
		margin-top:-10px;
		margin-bottom:20px;
	}

	.gray-color {
		color:#919fb6 !important;
	}

	.input-group-title>svg {
		margin-left: 15px;
	}

	.input-group-title>span {
		margin-left: 3px;
	}

	.invalidText {
		font-size: 11px;
		font-weight: 400;
		font-family: 'Noto Sans KR';
		color: #4c2e83;
	}

	.invalid-border {
		border: 1px solid red !important;
	}
</style>