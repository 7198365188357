<template>
	<div id="topmenu">
		<div class="card" style="height:51px;margin:0;">
			<div class="card-body">
				<ul v-if="menu">
					<li v-for="(item, idx) in menu" :key="idx" @click="clickMenu(item.path)" :class="{active: curPath.substr(0,12) == item.path}">
						{{item.title}}
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TopMenu',
		props: ['menu','curPath'],
		methods: {
			clickMenu: function (path) {
				this.$router.push(path);
			}
		}
	}
</script>

<style scoped>
#topmenu {
	margin-bottom: 17px;
}

#topmenu>.card>.card-body {
	padding:0;
	padding-left: 10px;
}

#topmenu>.card>.card-body>ul {
	display: flex;
	list-style: none;
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	padding:0;
}

#topmenu>.card>.card-body>ul>li {
	padding: 15px 22px 10px 22px;
	color:#919fb6;
	cursor: pointer;
}

#topmenu>.card>.card-body>ul>.active {
	color:#1a1e27;
	border-bottom: 2px solid #1a1e27;
}
</style>