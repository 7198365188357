<template>
	<div id="CategoryMenu">
		<ul class="leftmenu">
			<li v-for="(item,idx) in menu" :key="idx" @click="clickItem(item)" :class="{'active': category == item}">{{item}}</li>
		</ul>
		<ul class="rightmenu" v-if="rightmenu != 'off'">
			<li v-if="mode != 'upload' && mode != 'save'" @click="onClick('new')" class="new">
				신규작성
			</li>
			<li v-if="mode == 'upload'" @click="onClick('load')" class="load">
				파일 불러오기
			</li>
			<li v-if="mode == 'upload' || mode == 'save'" @click="onClick('submit')" class="submit">
				저장하기
			</li>
		</ul>
		<div v-if="mode == 'search'" class="search-box">
			<div class="searchbar">
				<input type="text" v-model="searchText" v-on:keypress.enter="onSearch" :placeholder="placeholder">
				<svg @click="onSearch" style="margin-top:2px" width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M25 25L19.2 19.2M22.3333 11.6667C22.3333 17.5577 17.5577 22.3333 11.6667 22.3333C5.77563 22.3333 1 17.5577 1 11.6667C1 5.77563 5.77563 1 11.6667 1C17.5577 1 22.3333 5.77563 22.3333 11.6667Z" stroke="#4C2E83" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CategoryMenu',
		props: ['category', 'menu', 'mode', 'rightmenu', 'placeholder'],
		data() {
			return {
				searchText: '',
			}
		},
		methods: {
			clickItem: function (item) {
				this.$emit('change', item);
			},
			onClick: function (emit) {
				this.$emit(emit);
			},
			onSearch: function (e) {
				this.searchText = e.target.value;
				if(this.searchText != '') {
					this.$emit('search', this.searchText);
					this.searchText = '';
				}
			}
		}
	}
</script>

<style scoped>
	#CategoryMenu {
		display: flex;
		font-size: 16px;
		font-weight: 400;
		justify-content: space-between;
	}

	ul {
		list-style: none;
		padding: 0;
		display: flex;
	}

	li {
		cursor: pointer;
		width:130px;
		height: 40px;
		box-sizing: border-box;
		padding: 8px 16px 9px 16px;
		text-align: left;
	}

	.active {
		color: white;
		background-color: #919fb6;
	}

	.rightmenu>li {
		font-size: 14px;
		border-radius: 20px;
		box-sizing: border-box;
		padding-top: 9.5px;
	}

	.rightmenu>.new {
		right: 23px;
		color: white;
		background-color: #526ae6;
		text-align: center;
		width: 91px;
		height: 40px;
	}

	.rightmenu>.load {
		color: white;
		background-color: #22ca80;
		right: 23px;
		text-align: center;
		width: 130px;
		height: 40px;
		margin-right: 15px;
	}

	.rightmenu>.submit {
		color: white;
		background-color: #526ae6;
		right: 23px;
		text-align: center;
		width: 91px;
		height: 40px;
	}

	.search-box {
		height: 43px;
	}

	.search-box>.searchbar {
		width: 100%;
		height: 43px;
		padding:5px 15px;
		background-color: white;
		display:flex;
		justify-content: space-between;
		border:1px solid #e3e5e5;
		border-radius: 48px;
	}

	.search-box>.searchbar>input {
		border: none;
		width: 80%;
	}

	.search-box>.searchbar>input::placeholder {
		font-size: 14px;
		font-weight: 400;
		color: #919fb6;
	}
</style>