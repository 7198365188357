<template>
	<div id="dataview">
		<top-menu></top-menu>

		<div style="padding:12px;">
			<category-menu :category="category" :menu="categoryMenu" rightmenu="off" :placeholder="placeholder" mode="search" @change="changeCategory" @search="onSearch"></category-menu>
		</div>

		<component :is="subComponent" :data="data"></component>

		<div id="select-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 600px;">
            <div class="modal-content">
                <div class="modal-body">
                    <h1 style="font-size:18px;margin-bottom:20px;">조회대상선택</h1>
										
										<table id="searchtable" class="table" style="margin-top:20px;">
											<thead class="bg-primary text-white">
												<tr>
													<th>코드</th>
												<th>이름</th>
												</tr>
											</thead>
											<tbody>
											</tbody>
										</table>
										
										<a class="closemodal" href="#" @click="closeModal">취 소</a>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
	</div>
</template>

<script>
	import CategoryMenu from '../../components/category'
	import SchoolComponent from './school'
	import TutorComponent from './tutor'

	export default {
		name: 'UploadPage',
		data() {
			return {
				subComponent: null,
				category: '강사정보',
				categoryMenu: ['강사정보','학교정보'],
				data: {},
				list: [],
				placeholder: '강사명을 입력하세요',
			}
		},
		mounted() {
			window.$.fn.dataTable.ext.errMode = 'none';
			this.subComponent = TutorComponent;
		},
		methods: {
			closeModal: function () {
				window.$('#select-modal').modal('hide');
			},
			loadData: function () {
				
			},
			changeCategory: function (v) {
				this.category = v;

				this.list = [];
				this.data = {};

				switch(v) {
					case '학교정보' :
						this.placeholder = '학교명을 입력하세요';
						this.subComponent = SchoolComponent;
						break;
					case '강사정보' :
						this.placeholder = '강사명을 입력하세요';
						this.subComponent = TutorComponent;
						break;
				}
				//this.loadData();
			},
			onSearch: function (s) {
				const url = 'https://asai.sacesapp.com/v1/';
				let api = 'Tutor';

				let params = { name: s };

				if(this.category == '학교정보') {
					api = 'School/unique';
					params = { schoolName: s };
				}

				this.$http.post(url + api, params)
				.then((res) => {
					if(res.data.success) {
						if(this.category == '학교정보') {
							this.list = res.data.schools;
						} else {
							this.list = res.data.list;
						}

						if(this.list.length == 1) {
							this.data = this.list[0];
						} else {
							if(window.$.fn.dataTable.isDataTable('#searchtable')) {
								const dt = window.$('#searchtable').dataTable();
								const data = this.list.map(function (x) {
										return {
											code: x.schoolCode ? x.schoolCode : x.code,
											name: x.schoolName ? x.schoolName : x.name,
										}
									});

								dt.fnClearTable();

								if(data.length > 0) {
									dt.fnAddData(data);
								}
							} else {
								window.$('#searchtable').dataTable({
									data: this.list.map(function (x) {
										return {
											code: x.schoolCode ? x.schoolCode : x.code,
											name: x.schoolName ? x.schoolName : x.name,
										}
									}),
									retrive: true,
									columns: [
										{data: 'code'},
										{data: 'name'},
									]
								});

								const self = this;
								window.$('#searchtable').on('click', 'tbody tr', function () {
									var row = window.$('#searchtable').dataTable().api().row(this);
									
									self.data = self.list[row[0][0]];
									window.$('#select-modal').modal('hide');
								});
							}

							window.$('#select-modal').modal('show');
						}
					}
				})
			}
		},
		components: {
			CategoryMenu,
			SchoolComponent,
			TutorComponent,
		}
	}
</script>

<style scoped>
#searchtable {
	width: 100% !important;
	text-align: center;
}

#select-modal h1 {
	text-align :center;
	font-size: 24px;
}

.closemodal {
	height: 60px;
	display: block;
	text-decoration: none;
	margin-top:20px;
	left: 50%;
	width: 60px;
	text-align:center;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-left: calc(50% - 30px);
}
</style>