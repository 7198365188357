<template>
	<div id="noticehomeview">
		<div class="row">
			<div class="col-lg-8">
				<div class="card">
					<div class="card-body main-box">
						<div @click="clickBack">
							<svg width="19" height="33" viewBox="0 0 19 33" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
							<rect width="19" height="33" fill="url(#pattern0)"/>
							<defs>
							<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
							<use xlink:href="#image0_5035_2502" transform="scale(0.0526316 0.030303)"/>
							</pattern>
							<image id="image0_5035_2502" width="19" height="33" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAhCAYAAAA74pBqAAAAyUlEQVRIDe3UsQrCMBAG4D54BwcXJxWc4+ZgB4eC4BtkEweH4gs4dVEHh/KXIJFoSe7+2kl6S3Ih+bgcIRkGjGxACyPGd/MPe1bbEsvNCbegG72uWdsdpjOD7fERUODfWQxyKlVZCqIwCVJjGkiFaSERY6AkxkIJ7ImqMMjnJc53t00X8afRXGHXBvlirwbjmCvGg6sDKkWFaYwEZYwAdZgS1GMKkMMEkMcCcFJcXPaOfpg/3vjJa/wN+7S4z/HrbCcdK+u0RFxoAURzl7tvYD0wAAAAAElFTkSuQmCC"/>
							</defs>
							</svg>
						</div>

						<div class="content-area">
							<h2>서식자료 등록하기</h2>

							<div class="option-box">
								<label style="margin-top: 8px;margin-bottom:0;">대상</label>
								<select class="form-control" v-model="data.category">
									<option value="공통">공통</option>
									<option value="예술강사">예술강사</option>
									<option value="운영학교">운영학교</option>
									<option value="비회원">비회원</option>
								</select>
							</div>

							<div class="form-group">
								<form class="mt-4">
									<input type="text" style="margin-bottom:15px;" class="form-control" v-model="data.subject">
									<textarea id="content" class="form-control" v-model="data.content">
									</textarea>
									
									<div class="file-section">
										<button type="button" class="btn waves-effect waves-light btn-light" @click="clickAttach">첨부파일</button>
										<ul class="files-box">
											<li v-for="(file, idx) in attachFiles" :key="idx">
												<span>{{file.filename}}</span>
												<div @click="clickFileDelete(idx)" style="opacity:0.4;">
													<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 17C13.1944 17 17 13.1943 17 8.5C17 3.80566 13.1944 0 8.5 0C3.80557 0 0 3.80566 0 8.5C0 13.1943 3.80557 17 8.5 17ZM12.2657 4.73438C12.5781 5.04663 12.5781 5.55322 12.2657 5.86572L9.63138 8.5L12.2657 11.1343C12.5781 11.4468 12.5781 11.9534 12.2657 12.2656C11.9533 12.5781 11.4467 12.5781 11.1343 12.2656L8.5 9.63135L5.86569 12.2656C5.55328 12.5781 5.04675 12.5781 4.73431 12.2656C4.42191 11.9534 4.42191 11.4468 4.73431 11.1343L7.36862 8.5L4.73431 5.86572C4.42191 5.55322 4.42191 5.04663 4.73431 4.73438C5.04672 4.42188 5.55325 4.42188 5.86569 4.73438L8.5 7.36865L11.1343 4.73438C11.4467 4.42188 11.9533 4.42188 12.2657 4.73438Z" fill="#8D94A0"/>
													</svg>
												</div>
											</li>
										</ul>
									</div>

									<input type="file" style="visibility: hidden;" ref="banner" @change="uploadBanner" id="banner" />
									<input type="file" style="visibility: hidden;" ref="file" @change="uploadAttach" id="file" />
								</form>
							</div>

							<div class="button-group">
								<button type="button" class="btn waves-effect waves-light btn-secondary" @click="clickCancel">취소하기</button>
								<button type="button" class="btn waves-effect waves-light btn-primary" @click="clickSave">등록하기</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-4">
				<div class="card">
					<div class="card-body main-box">
						<div class="iphone">
							<phone-view mode="form" :data="data"></phone-view>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Danger Header Modal -->
		<div v-if="showModel" id="danger-header-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="danger-header-modalLabel" aria-hidden="false">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-danger">
										<h4 class="modal-title" id="danger-header-modalLabel">Modal Heading</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
										<h5 class="mt-0">Danger Background</h5>
										<p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
												dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
												ac consectetur ac, vestibulum at eros.</p>
										<p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
												Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
												auctor.</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal">Close</button>
										<button type="button" class="btn btn-danger">Save changes</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->

		<!-- Primary Header Modal -->
		<div id="ok-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="primary-header-modalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-primary">
										<h4 class="modal-title" id="primary-header-modalLabel">알림
										</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
									<p>서식이 등록되었습니다</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light" data-dismiss="modal"
												@click="onOk">Close</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->
	</div>
</template>

<script>
import PhoneView from '../../../components/phoneview'

	export default {
		name: 'NoticeHomeView',
		data() {
			return {
				parentCategory: '',
				//category: '공통',
				//subject:'',
				//content: '',
				isFixed: false,
				//push: '',
				isViewMode: true,
				showModel: false,
				attachFiles: [],
				//banner: '',
				data: {
					category: '공통',
					subject: '',
					content: '',
					banner: '',
					fix: '',
					push: '',
				},
			}
		},
		mounted() {
			const self = this;

			this.parentCategory = this.$route.params.category;

			window.$('#content').val(this.data.content);

			window.$('#content').summernote({
				height: 250,
				minHeight: 436,
				maxHeight: 800,
				resize: true,
				focus: false,
				lang: 'ko-KR',
				disableResizeEditor: true,
				fontSizes: ['8','10','11','12','13','14','15','16','17','18','19','20','24','30','36'],
				fontNames: ['Noto Sans KR', 'Welcome Regular', 'Welcome Bold', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Merriweather'],
				toolbar: [
					['fontname', ['fontname']],
					['style', ['bold','italic','underline']],
					['fontsize', ['fontsize']],
					['color', ['color']],
					['Insert', ['picture']],
					['para', ['paragraph']],
					['view', ['codeview']],
				],
				callbacks: {
					onImageUpload: function(files) {
						console.log('image upload');
						for(let i = 0; i < files.length; i++) {
							self.sendFile(files[i]);
						}
					},
					onKeyup: function () {
						self.data.content = window.$('#content').summernote('code');
					}
				}
			});
		},
		updated() {
			

			//console.log('updated')
		},
		methods: {
			onOk: function () {
				this.$router.push({
					name: 'NoticeForm',
					params: {
						category: this.parentCategory,
					}
				})
			},
			sendFile: function (file) {
				const formData = new FormData();
				formData.append("file", file);
				this.$http.post(
						'https://api.heychurch.net/v1/File/single',
						formData,
						{
							headers: {
								'Content-Type': 'multipart/form-data'
							}
						}
				).then((res) => {
					if(res.data.success === true) {
						//editor.insertImage(welEditable, res.data.images[0])
						//var image = window.$('<img>').attr('src', res.data.images[0]);
            //window.$('#summernote').summernote("insertNode", image[0]);
						window.$('#content').summernote("editor.insertImage", res.data.images[0]);
						console.log(res.data.images[0]);
						this.data.content = window.$('#content').summernote('code');
					}
				}).catch((err) => {
					console.log(err);
					alert(err);
				});
			},
			clickFileDelete: function (fileidx) {
				/*this.$http.post('https://asai.sacesapp.com/v1/File/delete', {
					file: this.attachFiles[fileidx].url,
				}).then((res) => {
					console.log(res.data)
					if(res.data.success) {
						this.attachFiles.splice(fileidx,1);
					}
			});*/
				this.attachFiles.splice(fileidx,1);
			},
			clickBack: function () {
				this.$router.push({
					name: 'NoticeHome',
					params: {
						category: this.parentCategory,
					}
				})
			},
			clickAttach: function () {
				if(this.attachFiles.length < 3) {
					const el = window.document.querySelector("#file");
					el.click();
				} else {
					alert('첨부 파일은 3개 까지 가능합니다.');
				}
			},
			clickBanner: function () {
				const el = window.document.querySelector("#banner");
				el.click();
			},
			uploadBanner: function () {
				this.fileUpload(this.$refs.banner, 'banner');
			},
			uploadAttach: function () {
				this.fileUpload(this.$refs.file, 'attach');
			},
			fileUpload: function (e, target) {
				let url = '';
				let name = '';
				let file = e.files.length > 0 ? e.files[0] : undefined;

				if(!file) {
					if(target == 'banner') {
						/*this.$http.post('https://api.heychurch.net/v1/File/delete', {
							file: this.banner
						}).then((res) => {
							if(res.data.success) {
								this.banner = '';
							}
						})*/
						this.banner = '';
					}

					return;
				}

				name = file.name;
				const formData = new FormData();
				formData.append('file', file);

				this.$http.post(
					'https://api.heychurch.net/v1/File/single',
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}
				)
				.then((res) => {
					if(res.data.success === true) {
						url = res.data.images[0];

						if(target == 'banner') {
							this.banner = url;
						} else {
							this.attachFiles.push({
								filename: name,
								fileurl: url,
							});
						}
					}
				}).catch((err) => {
					console.log(err);
					alert(err);
				});
			},
			clickDelete: function () {
				this.showModel = true;
			},
			clickModify: function () {
				this.isViewMode = false; //Modify Mode.
			},
			clickCancel: function () {
				this.clickBack();
			},
			clickSave: function () {
				this.data.content = window.$('#content').summernote('code');

				let params = {
					subject: this.data.subject,
					content: this.data.content,
					banner: '',
					fix: '9',
					push: '',
					menu: 'form',
					category: this.data.category,
					filename: '',
					fileurl: '',
					filename2: '',
					fileurl2: '',
					filename3: '',
					fileurl3: '',
				};

				if(this.attachFiles.length >= 1) {
					params.filename = this.attachFiles[0].filename;
					params.fileurl = this.attachFiles[0].fileurl;
				}

				if(this.attachFiles.length >= 2) {
					params.filename2 = this.attachFiles[0].filename;
					params.fileurl2 = this.attachFiles[0].fileurl;
				}

				if(this.attachFiles.length >= 3) {
					params.filename3 = this.attachFiles[0].filename;
					params.fileurl3 = this.attachFiles[0].fileurl;
				}
				
				
				this.$http.post('https://asai.sacesapp.com/v1/Post/NewPost', params)
				.then((res) => {
					if(res.data.success === true) {
						this.isViewMode = true;
						//this.$event.$emit('toast','신규 서식자료가 등록되었습니다');
						window.$('#ok-modal').modal('show');
					}
				})
			}
		},
		components: {
			PhoneView,
		}
	}
</script>

<style scoped>
.main-box {
	height: calc(100vh - 88px) !important;
}

	.top-title {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.top-title>span {
		margin-top: 8px;
	}

	#noticehomeview h2 {
		margin-top:12px;
		font-size: 24px;
	}

	.content-area {
		margin-left: 27px;
	}

	#noticehomeview textarea {
		margin-top: 16px;
		height: 383px;
	}

	.button-group {
		display: flex;
		justify-content: right;
	}

	.button-group>button {
		margin-left: 20px;
	}

	.button-group>button:first {
		margin-left: 0px;
	}

	.option-table {
		width: 100%;
	}

	.option-table td {
		width: 50%;
		vertical-align: middle;
		padding:10.5px 15.5px;
	}

	.option-table .option-box {
		display: flex;
		justify-content:space-between;
	}

	.option-box {
		display: flex;
		justify-content:space-between;
	}

	.option-box select {
		width: 124px;
		height: 41px;
	}

	.option-box label {
		font-size: 18px;
		font-weight: 400;
	}

	.option-box h5 {
		font-size: 13px;
		font-weight: 400;
	}

	.file-section {
		margin-top: 25px;
	}

	.files-box {
		width: 100%;
		list-style: none;
		padding: 0;
		margin-top: 25px;
	}

	.files-box>li {
		width: 500px;
		color: #0065FF;
		display: flex;
		justify-content: space-between;
		font-size: 16px;
	}

	.blank-banner {
		color: #919fb6;
	}

	.fill-banner {
		color: #0065FF;
	}

.iphone {
	background-image:url('../../../assets/iphone.png');
	background-repeat: no-repeat;
	background-size: cover;
	height: 847px;
	width: 430px;
	padding: 70px 30px 50px 30px;
	margin:0 auto;
}
</style>