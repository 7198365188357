<template>
	<div>
		<top-menu></top-menu>

		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-4">
					<div class="card contactreserve">
						<chat-list @target="onTarget" />
					</div>
				</div>
				<div class="col-lg-4">
					<div class="card contactreserve">
						<chat-view />
					</div>
				</div>
				<div class="col-lg-4">
					<div class="row">
						<div class="col-lg-12" style="padding:0;">
							<div class="card contactreserve">
								<div class="callreserve-title">
									<h1>전화예약</h1>
									<div class="button-cover">
										<div class="button r" id="button-1">
											<input type="checkbox" class="checkbox" v-model="callReserveMode" @change="toggleCall" />
											<div class="knobs"></div>
											<div class="layer"></div>
										</div>
									</div>
								</div>
								<call-reserve />
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-12" style="padding:0;">
							<div class="card contactcategory">
								<h1>상담유형</h1>
								<contact-category />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Center modal content -->
		<div class="modal fade" id="target-modal" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content" style="border-radius:15px;">
					<div class="modal-body target">
						<div class="target-title">
							<h1>상담대상 지정하기</h1>
							<svg @click="onCloseTarget" width="27" height="29" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
								<line y1="-1" x2="36.5631" y2="-1" transform="matrix(0.681156 0.732139 -0.681156 0.732139 0 2.23077)" stroke="black" stroke-width="2"/>
								<line y1="-1" x2="36.5631" y2="-1" transform="matrix(-0.681156 0.732139 -0.681156 -0.732139 24.9053 0)" stroke="black" stroke-width="2"/>
							</svg>
						</div>
						<ul class="target-select">
							<li :class="{'target-select-active':curMenu=='예술강사'}" @click="clickCurMenu('예술강사')">예술강사</li>
							<li :class="{'target-select-active':curMenu=='운영학교'}" @click="clickCurMenu('운영학교')">운영학교</li>
						</ul>
						<div class="target-year" @change="onChangeTargetYear">
							<select v-model="targetYear">
								<option value="2022">2022</option>
								<option value="2021">2021</option>
								<option value="2020">2020</option>
								<option value="2019">2019</option>
							</select>
						</div>
						<div class="target-search">
							<input type="text" v-on:input="onInputTargetSearch" v-on:keypress.enter="onEnterTargetSearch">
							<svg @click="onEnterTargetSearch" style="margin-top:2px" width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M25 25L19.2 19.2M22.3333 11.6667C22.3333 17.5577 17.5577 22.3333 11.6667 22.3333C5.77563 22.3333 1 17.5577 1 11.6667C1 5.77563 5.77563 1 11.6667 1C17.5577 1 22.3333 5.77563 22.3333 11.6667Z" stroke="#4C2E83" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
						</div>

						<ul class="target-list">
							<li v-for="(item, idx) in targetList.filter(x => targetSearch.length == 0 || (curMenu == '예술강사' && x.name.indexOf(targetSearch) >= 0) || (curMenu == '운영학교' && x.schoolName.indexOf(targetSearch) >= 0))" :key="idx">
								<div v-if="curMenu == '예술강사'" @click="clickTutor(item)">
									<div class="default">
										{{item.name}} {{item.code}} | {{item.tutorfield}}
									</div>
									<div class="school">
										{{item.sido}} | {{item.schoolName}} 등
									</div>
								</div>
								<div v-else-if="curMenu == '운영학교'" @click="clickSchool(item)">
									<div class="default">
										{{item.schoolName}} | {{item.name}}
									</div>
									<div class="school">
										{{item.sido}} | {{item.schoolarrtime}}시수
									</div>
								</div>
								<!-- div v-else @click="clickAdmin(item)">
									<div class="default">
										{{item.name}} | {{item.tutorfield}}
									</div>
									<div class="school">
										{{item.schoolName}} | {{item.sido}}
									</div>
								</!-->
							</li>
						</ul>
					</div>
				</div><!-- /.modal-content -->
			</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->
	</div>
</template>

<script>
	import ChatList from './chatlist'
	import ChatView from './chatview'
	import CallReserve from './callreserve'
	import ContactCategory from './contactcategory'

	export default {
		name: 'ChatPage',
		data() {
			return {
				selectedUser: {
					code: '',
					type: '',
					name: '',
				},
				callReserveMode: false,
				targetSearch: '',
				targetYear: 2022,
				targetList: [],
				curMenu: '예술강사'
			}
		},
		created() {
			this.$http.get('https://asai.sacesapp.com/v1/Chat/config?id=chat')
			.then((res) => {
				if(res.data.success) {
					this.callReserveMode = res.data.config.val == 'off' ? true : false;
				}
			})
		},
		mounted() {
			
		},
		methods: {
			clickCurMenu: function (menu) {
				this.curMenu = menu;
				this.loadData();
			},
			clickTutor: function (item) {
				this.$event.$emit('chat', item);
				window.$('#target-modal').modal('hide');
			},
			clickSchool: function (item) {
				this.$event.$emit('chat', item);
				window.$('#target-modal').modal('hide');
			},
			onChangeTargetYear: function () {
				this.loadData();
			},
			onInputTargetSearch: function (e) {
				this.targetSearch = e.target.value.trim();
			},
			onEnterTargetSearch: function () {
				this.targetSearch = '';
			},
			onTarget: function () {
				window.$('#target-modal').modal('show');
				this.loadData();
			},
			onCloseTarget: function () {
				window.$('#target-modal').modal('hide');
			},
			toggleCall: function () {
				this.$http.post('https://asai.sacesapp.com/v1/Chat/udtcnf?id=chat&val=' + (this.callReserveMode == true ? 'off' : 'on'))
				.then((res) => {
					if(res.data.success == false) {
						this.callReserveMode = !this.callReserveMode;
					}
				})
			},
			loadData: function () {
				this.$event.$emit('spinner', true);

				this.targetList = [];
				this.$http.post('https://asai.sacesapp.com/v1/User/lus', {
					year: this.targetYear.toString(),
					type: this.curMenu,
					limit: "2000",
					offset: "0",
				}).then((res) => {
					if(res.data.success == true) {
						switch(this.curMenu) {
							case '예술강사' :
								this.targetList = res.data.users;
								break;
							case '운영학교' :
								this.targetList = res.data.users;
								break;
							case '운영기관' :
								this.targetList = res.data.users;
								break;
						}
					}
					this.$event.$emit('spinner', false);
				})
			},
		},
		components: {
			ChatList,
			ChatView,
			CallReserve,
			ContactCategory,
		}
	}
</script>

<style scoped>
	.dim {
		position: absolute;
		z-index: 900;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.25);
	}

	h1 {
		font-weight: 400;
		font-family: 'Welcome Regular';
		font-size: 24px;
		color: black;
	}

	h2 {
		font-weight: 700;
		font-family: 'Welcome Bold';
		font-size: 16px;
		color: #1a1e27;
	}

	.contactcategory {
		padding: 24px;
	}

	.contactreserve {
		padding: 24px 24px 10px 24px;
	}

.callreserve-title {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.button-cover {
	display: block;
	margin-top: -7px;
}

.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 74px;
  height: 36px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 2px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #ebf7fc;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 1 */
#button-1 .knobs:before {
  content: "Off";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #03a9f4;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-1 .checkbox:checked + .knobs:before {
  content: "On";
  left: 42px;
  background-color: #f44336;
}

#button-1 .checkbox:checked ~ .layer {
  background-color: #fcebeb;
}

.target {
	padding:35px;
}
.target-title {
	display: flex;
	justify-content: space-between;
}

.target-title>h1 {
	font-family: 'Noto Sans KR';
	font-size: 24px;
	font-weight: 400px;
}

.target-select {
	display: flex;
	padding: 0;
	list-style: none;
	margin-top: 20px;
}

.target-select>li {
	font-size: 13px;
	font-weight: 400;
	width: 90px;
	height: 37px;
	margin-right: 9.76px;
	border-radius: 15px;
	border:1px solid #c4c4c4;
	background-color: white;
	text-align: center;
	padding-top:7px;
	cursor: pointer;
}

.target-select-active {
	background-color: #c4c4c4 !important;
	color: black;
}

.target-year>select {
	font-size: 20px;
	font-weight: 400;
	font-family: 'Noto Sans KR';
	border: none;
	height: 30px;
}

.target-search {
		width: 100%;
		height: 43px;
		padding:5px 15px;
		background-color: white;
		display:flex;
		justify-content: space-between;
		border:1px solid #e3e5e5;
		border-radius: 48px;
		margin-top: 10px;
		margin-bottom: 25px;
	}

	.target-search>input {
		border: none;
		width: 80%;
	}

	.target-search>input::placeholder {
		font-size: 14px;
		font-weight: 400;
		color: #919fb6;
	}

	.target-list {
		margin: 0;
		padding: 0;
		list-style: none;
		margin-left: 24px;
		margin-top: 17px;
		min-height: 400px;
		max-height: 400px;
		overflow: scroll;
	}

	.target-list>li {
		height: 64px;
		padding: 0;
		margin: 0;
		text-align: left;
		cursor: pointer;
	}

	.default {
		margin-bottom: 4px;
		font-size: 16px;
		font-weight: 400;
		color: #090a0a;
	}

	.school {
		font-size: 14px;
		font-weight: 400;
		color: #778192;
	}
</style>