<template>
	<div>
		<table id="noticelist" class="table" style="width:100%">
			<thead class="bg-primary text-white">
				<tr>
					<th>번호</th>
					<th>학교명</th>
					<th>학교코드</th>
					<th>담당자명</th>
					<th>담당자연락처</th>
					<th>담당자이메일</th>
					<th>메모</th>
					<th>삭제</th>
				</tr>
			</thead>
			<tbody>
			</tbody>
		</table>
	</div>
</template>

<script>
	export default {
		name: 'SchoolComponent',
		props: ['list'],
		data() {
			return {
				data: []
			}
		},
		watch: {
			list: function () {
				this.data = [];

				let idx = 1;
				window.$.fn.dataTable.ext.errMode = 'none';

				if(window.$.fn.dataTable.isDataTable('#noticelist')) {
						const dt = window.$('#noticelist').dataTable();
						let idx = this.list.length;
						const data = this.list.map((x) => {
							this.data.push(x);

							return {
								seq: idx++,
								schoolName: x.schoolName,
								schoolCode: x.schoolCode,
								name: x.name,
								phone: x.phone,
								email: x.email,
								memo: x.memo,
							}
						});

						dt.fnClearTable();

						if(data.length > 0) {
							dt.fnAddData(data);
						}
				} else {
					window.$('#noticelist').dataTable({
						data: this.list.map((x) => {
							this.data.push(x);

							return {
								seq: idx++,
								schoolName: x.schoolName,
								schoolCode: x.schoolCode,
								name: x.name,
								phone: x.phone,
								email: x.email,
								memo: x.memo,
							}
						}),
						retrive: true,
						columns: [
							{data:'seq'},
							{data:'schoolName'},
							{data:'schoolCode'},
							{data:'name'},
							{data:'phone'},
							{data:'email'},
							{data:'memo'},
							{data:''}
						],
						columnDefs: [
							{ 
								targets: [7],
								searchable: false,
								orderable: false,
								classNane: 'text-center',
								render: function(data, type, full, meta) {
									return `<input type="checkbox" style="width:20px;height:20px;" class="accept-checkbox" data-row=${meta.row} data-col=${meta.col} >`;
								}
							},
						]
					});
				}

				const self = this;
				window.$('#noticelist').on('change', 'tbody tr td input', function (e) {
					const row = parseInt(e.target.dataset.row);
					
					self.data[row].accept = e.target.checked ? 'D' : 'Y';

					self.$emit('change', self.data);
				})
			}
		}
	}
</script>

<style scoped>

</style>