<template>
	<div id="noticehomeview">
		<div class="row">
			<div class="col-lg-8">
				<div class="card">
					<div class="card-body main-box">
						<div @click="clickBack">
							<svg width="19" height="33" viewBox="0 0 19 33" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
							<rect width="19" height="33" fill="url(#pattern0)"/>
							<defs>
							<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
							<use xlink:href="#image0_5035_2502" transform="scale(0.0526316 0.030303)"/>
							</pattern>
							<image id="image0_5035_2502" width="19" height="33" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAhCAYAAAA74pBqAAAAyUlEQVRIDe3UsQrCMBAG4D54BwcXJxWc4+ZgB4eC4BtkEweH4gs4dVEHh/KXIJFoSe7+2kl6S3Ih+bgcIRkGjGxACyPGd/MPe1bbEsvNCbegG72uWdsdpjOD7fERUODfWQxyKlVZCqIwCVJjGkiFaSERY6AkxkIJ7ImqMMjnJc53t00X8afRXGHXBvlirwbjmCvGg6sDKkWFaYwEZYwAdZgS1GMKkMMEkMcCcFJcXPaOfpg/3vjJa/wN+7S4z/HrbCcdK+u0RFxoAURzl7tvYD0wAAAAAElFTkSuQmCC"/>
							</defs>
							</svg>
						</div>

						<div class="content-area">
							<h2>공지사항 등록하기</h2>

							<table class="option-table">
								<tr>
									<td>
										<div class="option-box">
											<label>대상</label>
											<select class="form-control" v-model="data.category">
												<option value="공통">공통</option>
												<option value="예술강사">예술강사</option>
												<option value="운영학교">운영학교</option>
												<option value="운영기관">운영기관</option>
											</select>
										</div>
									</td>
									<td>
										<div class="option-box">
											<div>
												<label>배너등록</label>
												<h5>배너 이미지(3MB이하)가 업로드 되면 배너로 등록</h5>
											</div>
											<div @click="clickBanner" :class="{'blank-banner':data.banner=='', 'fill-banner':data.banner!=''}">{{data.banner == '' ? '미등록' : '등록됨'}}</div>
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<div class="option-box">
											<div>
												<label>상단고정</label>
												<h5>등록직후 상단에 고정됩니다</h5>
											</div>
											<div class="button-cover">
												<div class="button r" id="button-1">
													<input id="button-1" type="checkbox" class="checkbox" v-model="isFixed" />
													<div class="knobs"></div>
													<div class="layer"></div>
												</div>
											</div>
										</div>
									</td>
									<td>
										<div class="option-box">
											<div>
												<label>푸시알람</label>
												<h5>설정된 알림시간에 푸시알림 전송</h5>
											</div>
											<div @click="pushModal">{{data.push==''?'즉시전송':data.push}}</div>
										</div>
									</td>
								</tr>
							</table>
							<div class="form-group">
								<form class="mt-4">
									<input type="text" style="margin-bottom:15px;" class="form-control" v-model="data.subject">
									<textarea id="content" class="form-control" v-model="data.content">
									</textarea>
									
									<div class="file-section">
										<button type="button" class="btn waves-effect waves-light btn-light" @click="clickAttach">첨부파일</button>
										<ul class="files-box">
											<li v-for="(file, idx) in attachFiles" :key="idx">
												<span>{{file.filename}}</span>
												<div @click="clickFileDelete(idx)" style="opacity:0.4;">
													<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 17C13.1944 17 17 13.1943 17 8.5C17 3.80566 13.1944 0 8.5 0C3.80557 0 0 3.80566 0 8.5C0 13.1943 3.80557 17 8.5 17ZM12.2657 4.73438C12.5781 5.04663 12.5781 5.55322 12.2657 5.86572L9.63138 8.5L12.2657 11.1343C12.5781 11.4468 12.5781 11.9534 12.2657 12.2656C11.9533 12.5781 11.4467 12.5781 11.1343 12.2656L8.5 9.63135L5.86569 12.2656C5.55328 12.5781 5.04675 12.5781 4.73431 12.2656C4.42191 11.9534 4.42191 11.4468 4.73431 11.1343L7.36862 8.5L4.73431 5.86572C4.42191 5.55322 4.42191 5.04663 4.73431 4.73438C5.04672 4.42188 5.55325 4.42188 5.86569 4.73438L8.5 7.36865L11.1343 4.73438C11.4467 4.42188 11.9533 4.42188 12.2657 4.73438Z" fill="#8D94A0"/>
													</svg>
												</div>
											</li>
										</ul>
									</div>

									<input type="file" style="visibility: hidden;" ref="banner" @change="uploadBanner" id="banner" accept="image/*"/>
									<input type="file" style="visibility: hidden;" ref="file" @change="uploadAttach" id="file" />
								</form>
							</div>

							<div class="button-group">
								<button type="button" class="btn waves-effect waves-light btn-secondary" @click="clickCancel">취소하기</button>
								<button type="button" class="btn waves-effect waves-light btn-primary" @click="clickSave">등록하기</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-4">
				<div class="card">
					<div class="card-body main-box">
						<div class="iphone">
							<phone-view :data="data" mode="notice"></phone-view>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="push-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h1>푸시알림시간 설정하기</h1>
										<div class="notice">
											시간 설정 예시) 오후 2시 → 14시
										</div>
										<div class="input-group">
											<input v-model="pushMonth" class="dday" />
											<span class="label">월</span>
											<input v-model="pushDay" class="dday" />
											<span class="label">일</span>
											<input v-model="pushHour" class="dday" />
											<span class="label">시</span>
											<input v-model="pushMinute" class="dday" />
											<span class="label">분</span>
										</div>

										<button @click="setPush">확 인</button>
										<a href="#" @click="closeModal">취 소</a>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->

		<!-- Primary Header Modal -->
		<div id="ok-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="primary-header-modalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-primary">
										<h4 class="modal-title" id="primary-header-modalLabel">알림
										</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
									<p>공지사항이 등록되었습니다</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"  data-dismiss="modal" @click="onOk">Close</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->
	</div>
</template>

<script>
import PhoneView from '../../../components/phoneview'

	export default {
		name: 'NoticeHomeView',
		data() {
			return {
				parentCategory: '',
				//category: '공통',
				//subject:'',
				//content: '',
				isFixed: false,
				//push: '',
				isViewMode: true,
				showModel: false,
				attachFiles: [],
				//banner: '',

				pushMonth: '00',
				pushHour: '00',
				pushDay: '00',
				pushMinute: '00',

				data: {
					category: '공통',
					subject: '',
					content: '',
					banner: '',
					fix: '',
					push: '',
				},
			}
		},
		created() {
		},
		mounted() {
			const self = this;
			this.parentCategory = this.$route.params.category;

			window.$('#content').val(this.data.content);

			window.$('#content').summernote({
				height: 250,
				minHeight: 318,
				maxHeight: 800,
				resize: true,
				focus: false,
				lang: 'ko-KR',
				disableResizeEditor: true,
				fontSizes: ['8','10','11','12','13','14','15','16','17','18','19','20','24','30','36'],
				fontNames: ['Noto Sans KR', 'Welcome Regular', 'Welcome Bold', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Merriweather'],
				toolbar: [
					['fontname', ['fontname']],
					['style', ['bold','italic','underline']],
					['fontsize', ['fontsize']],
					['color', ['color']],
					['Insert', ['picture']],
					['para', ['paragraph']],
					['view', ['codeview']],
				],
				callbacks: {
					onImageUpload: function(files) {
						console.log('image upload');
						for(let i = 0; i < files.length; i++) {
							self.sendFile(files[i]);
						}
					},
					onKeyup: function () {
						self.data.content = window.$('#content').summernote('code');
					}
				}
			});
		},
		updated() {
			//console.log('updated')
		},
		methods: {
			onOk: function () {
				this.$router.push({
					name: 'NoticeHome',
					params: {
						category: this.parentCategory,
					}
				})
			},
			setPush: function () {
				this.showPushModal = false;

				let m = this.pushMonth;
				if(m < 10) {
					m = '0' + m.toString();
				}

				let d = this.pushDay;
				if(d < 10) {
					d = '0' + d.toString();
				}

				let h = this.pushHour;
				if(h < 10) {
					h = '0' + h.toString();
				}

				let i = this.pushMinute;
				if(i < 10) {
					i = '0' + i.toString();
				}

				this.data.push = `${m}/${d} ${h}:${i}`;
				this.$event.$emit('toast', '푸시알림시간이 설정되었습니다');
				this.closeModal();
			},
			closeModal: function () {
				window.$('#push-modal').modal('hide');
			},
			pushModal: function () {
				if(this.data.push == '') {
					const dt = new Date();
					this.pushMonth = dt.getMonth() + 1;
					this.pushDay = dt.getDate();
					this.pushHour = dt.getHours();
					this.pushMinute = dt.getMinutes();
				} else {
					this.pushMonth = parseInt(this.data.push.substr(0,2)).toString();
					this.pushDay = parseInt(this.data.push.substr(3,2)).toString();
					this.pushHour = parseInt(this.data.push.substr(6,2)).toString();
					this.pushMinute = parseInt(this.data.push.substr(9,2)).toString();
				}
				
				window.$('#push-modal').modal('show');
			},
			sendFile: function (file) {
				const formData = new FormData();
				formData.append("file", file);
				this.$http.post(
						'https://api.heychurch.net/v1/File/single',
						formData,
						{
							headers: {
								'Content-Type': 'multipart/form-data'
							}
						}
				).then((res) => {
					if(res.data.success === true) {
						//editor.insertImage(welEditable, res.data.images[0])
						//var image = window.$('<img>').attr('src', res.data.images[0]);
            //window.$('#summernote').summernote("insertNode", image[0]);
						window.$('#content').summernote("editor.insertImage", res.data.images[0]);
						console.log(res.data.images[0]);
						this.data.content = window.$('#content').summernote('code');
					}
				}).catch((err) => {
					console.log(err);
					alert(err);
				});
			},
			clickFileDelete: function (fileidx) {
				/*this.$http.post('https://asai.sacesapp.com/v1/File/delete', {
					file: this.attachFiles[fileidx].url,
				}).then((res) => {
					console.log(res.data)
					if(res.data.success) {
						this.attachFiles.splice(fileidx,1);
					}
			});*/
				this.attachFiles.splice(fileidx,1);
			},
			clickBack: function () {
				this.$router.push({
					name: 'NoticeHome',
					params: {
						category: this.parentCategory,
					}
				})
			},
			clickAttach: function () {
				if(this.attachFiles.length < 3) {
					const el = window.document.querySelector("#file");
					el.click();
				} else {
					alert('첨부 파일은 3개 까지 가능합니다.');
				}
			},
			clickBanner: function () {
				const el = window.document.querySelector("#banner");
				el.click();
			},
			uploadBanner: function () {
				this.fileUpload(this.$refs.banner, 'banner');
			},
			uploadAttach: function () {
				this.fileUpload(this.$refs.file, 'attach');
			},
			fileUpload: function (e, target) {
				let url = '';
				let name = '';
				let file = e.files.length > 0 ? e.files[0] : undefined;

				if(!file) {
					if(target == 'banner') {
						/*this.$http.post('https://api.heychurch.net/v1/File/delete', {
							file: this.banner
						}).then((res) => {
							if(res.data.success) {
								this.banner = '';
							}
						})*/
						this.data.banner = '';
					}

					return;
				}

				name = file.name;
				const formData = new FormData();
				formData.append('file', file);

				this.$http.post(
					'https://api.heychurch.net/v1/File/single',
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}
				)
				.then((res) => {
					if(res.data.success === true) {
						url = res.data.images[0];

						if(target == 'banner') {
							this.data.banner = url;
						} else {
							this.attachFiles.push({
								filename: name,
								fileurl: url,
							});
						}
					}
				}).catch((err) => {
					console.log(err);
					alert(err);
				});
			},
			clickDelete: function () {
				this.showModel = true;
			},
			clickModify: function () {
				this.isViewMode = false; //Modify Mode.
			},
			clickCancel: function () {
				this.clickBack();
			},
			clickSave: function () {
				this.data.content = window.$('#content').summernote('code');

				let params = {
					subject: this.data.subject,
					content: this.data.content,
					banner: this.data.banner,
					fix: this.isFixed === true ? '0' : '9',
					push: this.data.push,
					menu: 'notice',
					category: this.data.category,
				};

				if(this.attachFiles.length >= 1) {
					params.filename = this.attachFiles[0].filename;
					params.fileurl = this.attachFiles[0].fileurl;
				}

				if(this.attachFiles.length >= 2) {
					params.filename2 = this.attachFiles[1].filename;
					params.fileurl2 = this.attachFiles[1].fileurl;
				}

				if(this.attachFiles.length >= 3) {
					params.filename3 = this.attachFiles[2].filename;
					params.fileurl3 = this.attachFiles[2].fileurl;
				}
				
				
				this.$http.post('https://asai.sacesapp.com/v1/Post/NewPost',params)
				.then((res) => {
					if(res.data.success === true) {
						this.isViewMode = true;
						this.$event.$emit('toast','신규 공지사항이 등록되었습니다');
						window.$('#ok-modal').modal('show');
					}
				})
			}
		},
		components: {
			PhoneView,
		}
	}
</script>

<style scoped>
.main-box {
	height: calc(100vh - 88px) !important;
}

	.top-title {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.top-title>span {
		margin-top: 8px;
	}

	#noticehomeview h2 {
		margin-top:12px;
		font-size: 24px;
	}

	.content-area {
		margin-left: 27px;
	}

	#noticehomeview textarea {
		margin-top: 16px;
		height: 383px;
	}

	.button-group {
		display: flex;
		justify-content: right;
	}

	.button-group>button {
		margin-left: 20px;
	}

	.button-group>button:first {
		margin-left: 0px;
	}

	.option-table {
		width: 100%;
	}

	.option-table td {
		width: 50%;
		vertical-align: middle;
		padding:10.5px 15.5px;
	}

	.option-table .option-box {
		display: flex;
		justify-content:space-between;
	}

	.option-box select {
		width: 124px;
		height: 41px;
	}

	.option-box label {
		font-size: 18px;
		font-weight: 400;
	}

	.option-box h5 {
		font-size: 13px;
		font-weight: 400;
	}

	.file-section {
		margin-top: 25px;
	}

	.files-box {
		width: 100%;
		list-style: none;
		padding: 0;
		margin-top: 25px;
	}

	.files-box>li {
		width: 500px;
		color: #0065FF;
		display: flex;
		justify-content: space-between;
		font-size: 16px;
	}

	.blank-banner {
		color: #919fb6;
	}

	.fill-banner {
		color: #0065FF;
	}

 .iphone {
	background-image:url('../../../assets/iphone.png');
	background-repeat: no-repeat;
	background-size: cover;
	height: 847px;
	width: 430px;
	padding: 70px 30px 50px 30px;
	margin:0 auto;
}


.button-cover {
	display: block;
	margin-top: -7px;
}

.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 74px;
  height: 36px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 2px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #ebf7fc;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 1 */
#button-1 .knobs:before {
  content: "No";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #03a9f4;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-1 .checkbox:checked + .knobs:before {
  content: "Yes";
  left: 42px;
  background-color: #f44336;
}

#button-1 .checkbox:checked ~ .layer {
  background-color: #fcebeb;
}

#button-1 .knobs,
#button-1 .knobs:before,
#button-1 .layer {
  transition: 0.3s ease all;
}

#push-modal {
	text-align: center;
}
#push-modal h1 {
		font-size: 20px !important;
		font-weight: 700;
		font-family: 'Welcome Bold';
		color: #616774;
		display: block;
	}

	#push-modal button {
		width: 279px !important;
		height: 48px;
		background-color: #4c2e84;
		color: white;
		border-radius: 48px;
		font-weight: 400;
		font-size: 16px;
		margin-left:0 !important;
		font-family: 'Welcome Regular';
		margin-bottom: 18px;
	}

	#push-modal a {
		display: block;
		font-weight: 400;
		font-size: 16px;
		color: #1a1e27;
		font-family: 'Welcome Regular';
		text-decoration: none;
	}

	#push-modal a:focus {
		outline: none;
	}

	#push-modal .notice {
		font-size: 13px;
		font-weight: 400px;
		color: #8d94a0;
		width: 100%;
		height: 19px;
		text-align:center;
		margin-bottom:26px;
		margin-top: 10px;
	}
	#push-modal .input-group {
		display: flex;
		margin-bottom: 23px;
		width: 470px;
		left: 50%;
		margin-left: -150px;
	}

	#push-modal .dday {
		display: block;
		width: 47px;
		height: 40px;
		border: 1px solid #c4c4c4;
		border-radius: 10px;
		font-size: 14px;
		font-weight: 400;
		color: black;
		text-align: center;
		padding-bottom:7px;
		margin-left:10px;
		margin-right: 5px;
	}

	#push-modal .dday:focus {
		outline: none;
	}

	#push-modal .label {
		display: block;
		font-size: 14px;
		font-weight: 400;
		color: #616774;
		margin-top: auto;
		margin-left: -3px;
	}
</style>