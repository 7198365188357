<template>
	<div>
		<top-menu :menu="topmenu" :curPath="this.$route.path"></top-menu>

		<div class="container-fluid">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'NoticePage',
		data() {
			return {
				topmenu: [
					{
						title: '공지홈',
						path: '/notice/home',
					},
					{
						title: '서식자료실',
						path: '/notice/form',
					}
				]
			}
		},
		mounted() {
			if(this.$route.path == '/notice') {
				
				this.$router.push('/notice/home');
			}
		}
	}
</script>

<style scoped>

</style>