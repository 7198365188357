<template>
	<div>
		<table id="noticelist" class="table" style="width:100%">
			<thead class="bg-primary text-white">
				<tr>
					<th>번호</th>
					<th>이름</th>
					<th>담당분야</th>
					<th>연락처</th>
					<th>이메일주소</th>
					<th>삭제</th>
				</tr>
			</thead>
			<tbody>
			</tbody>
		</table>
	</div>
</template>

<script>
	export default {
		name: 'GeneralComponent',
		props: ['list'],
		data() {
			return {
				data: []
			}
		},
		watch: {
			list: function () {
				this.data = [];
				console.log(this.list);

				let idx = 1;
				window.$.fn.dataTable.ext.errMode = 'none';

				if(window.$.fn.dataTable.isDataTable('#noticelist')) {
						const dt = window.$('#noticelist').dataTable();
						let idx = this.list.length;
						const data = this.list.map((x) => {
							this.data.push(x);

							return {
								seq: idx++,
								name: x.name,
								incharge: x.incharge,
								phone: x.phone,
								email: x.email,
							}
						});

						dt.fnClearTable();

						if(data.length > 0) {
							dt.fnAddData(data);
						}
				} else {
					window.$('#noticelist').dataTable({
						data: this.list.map((x) => {
							this.data.push(x);

							return {
								seq: idx++,
								name: x.name,
								incharge: x.incharge,
								phone: x.phone,
								email: x.email,
							}
						}),
						retrive: true,
						columns: [
							{data:'seq'},
							{data:'name'},
							{data:'incharge'},
							{data:'phone'},
							{data:'email'},
							{data:''},
						],
						columnDefs: [
							{ 
								targets: [5],
								searchable: false,
								orderable: false,
								classNane: 'text-center',
								render: function(data, type, full, meta) {
									return `<input type="checkbox" style="width:20px;height:20px;" class="accept-checkbox" data-row=${meta.row} data-col=${meta.col} >`;
								}
							},
						]
					});
				}

				const self = this;
				window.$('#noticelist').on('change', 'tbody tr td input', function (e) {
					const row = parseInt(e.target.dataset.row);
					
					self.data[row].accept = e.target.checked ? 'D' : 'Y';

					self.$emit('change', self.data);
				})
			}
		}
	}
</script>

<style scoped>

</style>