<template>
	<div id="login" class="login-background">
      <div class="login-box">
        <img class="logo" src="../../assets/logo2.png">
        <div class="title">
          <h1>Login</h1>
          <h2>관리자페이지</h2>
        </div>
        <div class="input-group">
          <label>ID</label>
          <label v-if="emailError" class="errorText">이메일을 확인해 주세요.</label>
          <input type="text" v-model="email" @blur="onBlurEmail" :class="{'error':emailError}" ref="email" placeholder="e-mail을 입력하세요">
        </div>
        <div class="input-group">
          <label>Password</label>
          <label v-if="passwordError" class="errorText">비밀번호를 확인해 주세요.</label>
          <input type="password" v-model="password" @blur="onBlurPassword" :class="{'error':passwordError}" ref="password" placeholder="비밀번호를 입력하세요">
        </div>
        <div class="remember">
          <input type="checkbox" v-model="saveEmail">
          <label>ID/Password 기억하기</label>
        </div>
        <button class="submit" @click="onLogin">Login</button>
      </div>
      <div class="version">version 1.0</div>

			<!-- Danger Header Modal -->
			<div id="error-modal" class="modal fade" tabindex="-1" role="dialog"
					aria-labelledby="danger-header-modalLabel" aria-hidden="false">
					<div class="modal-dialog modal-dialog-centered">
							<div class="modal-content">
									<div class="modal-header modal-colored-header bg-danger">
											<h4 class="modal-title" id="danger-header-modalLabel">오류</h4>
											<button type="button" class="close" data-dismiss="modal"
													aria-hidden="true">×</button>
									</div>
									<div class="modal-body">
											<h5 class="mt-0">로그인오류</h5>
											<p>{{errMessage}}</p>
									</div>
									<div class="modal-footer">
											<button type="button" class="btn btn-light"
													data-dismiss="modal">닫 기</button>
									</div>
							</div><!-- /.modal-content -->
					</div><!-- /.modal-dialog -->
			</div><!-- /.modal -->
    </div>
</template>

<script>
	export default {
		name: "LoginPage",
		data() {
			return {
				email: '',
        password: '',
        saveEmail: false,
        emailError: false,
        passwordError: false,
        errMessage: '',
			}
		},
		created() {
			if(this.$cookies.isKey('email')) {
				this.saveEmail = true;
				this.email = this.$cookies.get('email');
			}
		},
		methods: {
      onLogin: function () {
        this.email = this.email.trim();
        this.password = this.password.trim();
        var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

        if(this.email == '' || this.email.match(regExp) == null) {
          this.emailError = true;
          this.$refs.email.focus();
          return
        }

        if(this.password == '') {
          this.passwordError = true;
          this.$refs.password.focus();
          return;
        }

        this.$http.post('https://asai.sacesapp.com/v1/User/login',
					{
						id: this.email,
						password: this.password,
					}).then(async (res) => {
						if(res.data.success == true) {
							if(res.data.user.type != '운영기관') {
								this.errMessage = '권한이 없습니다';
								window.$('#error-modal').modal('show');
								return;
							} else {
								this.$store.state.user = res.data.user;
								if(this.saveEmail) {
									this.$cookies.set('email', this.email, "3600d");
								}
								this.$cookies.set('user', this.$store.state.user, "1d");
                this.$cookies.set('status', this.email, "1d");
								this.$router.push('/');
							}
						} else {
              this.errMessage = res.data.errorMessage;
              window.$('#error-modal').modal('show');
            }
					}).catch((err) => {
						console.log(err);
					})
      },
      onBlurEmail: function () {
        if(this.emailError == true) {
          this.emailError = false;
        }
      },
      onBlurPassword: function () {
        if(this.emailPasswsord == true) {
          this.emailPassword = false;
        }
      }
    }
	}
</script>

<style scoped>
.login-background {
  background-color: #60498c;
  width: 100vw;
  height: 100vh;
  position: relative;
	margin-left: -260px;
	z-index: 10;
}

.blank-background {
  background-color: white;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.login-box {
  position: absolute;
  width: 538px;
  height: 715px;
  background-color: white;
  border-radius: 15px;
  padding: 52px 55px;
  left: 50%;
  top: 50%;
  margin-left: -219px;
  margin-top: -360px;
}

.login-box>.logo {
  position: absolute;
  left: 50%;
  top: 47px;
  width: 169px;
  height:146px;
  margin-left: -85px;
}

.login-box>.title {
  display: flex;
  margin-top: 200px;
}

.login-box>.title>h1 {
  font-family: 'Welcome Regular';
  font-weight: 400;
  font-size: 36px;
  color: #1a1e27;
}

.login-box>.title>h2 {
  font-family: 'Welcome Regular';
  font-size: 18px;
  font-weight: 400;
  color: #1a1e27;
  margin-left: 10px;
  margin-top: 15px;
}

.login-box>.input-group {
  margin-bottom: 18px;
}

.login-box>.input-group>label {
  font-family: 'Welcome Regular';
  font-size: 18px;
  font-weight: 400;
  color: #1a1e27;
}

.login-box>.input-group>input {
  width: 429px;
  height: 55px;
  border: 1px solid black;
  font-size: 18px;
  font-family: 'Welcome Regular';
  padding-left: 10px;
}

.login-box>.input-group>input::placeholder {
  font-size: 18px;
  font-family: 'Welcome Regular';
  color: #adadad;
}

.login-box>.remember {
  margin-top: 33px;
}

.login-box>.remember>input {

}

.login-box>.remember>label {
  margin-left: 10px;
  color: #1a1e27;
  font-size: 18px;
  font-family: 'Welcome Regular';
}

.login-box>.submit {
  width: 429px;
  height: 50px;
  font-size: 18px;
  font-family: 'Noto Sans KR';
  font-weight: 300;
  color: white;
  border: none;
  background-color: #3563db;
  margin-top : 30px;
}

.login-box>.submit:focus {
  outline: none;
}

.version {
  position: absolute;
  color: white;
  font-size: 14px;
  right: 30px;
  bottom: 30px;
}

.remember {
  display: flex;
}

.remember > input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.remember > label {
  display: block;
  margin-top:-2px;
}
</style>