<template>
	<div>
		<top-menu></top-menu>

		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-4">
					<div class="card">
						<div class="card-body">
							<h1 class="card-title">어플리케이션 가입 추이</h1>
							<line-chart id="appjoin" :data="appjoin" xkey="date" ykeys='["count"]' line-colors='["#36A2EB", "#FF6384"]' grid="true" grid-text-weight="bold" resize="true"></line-chart>
						</div>
					</div>
				</div>

				<div class="col-lg-4">
					<div class="card">
						<div class="card-body">
							<h1 class="card-title">주간 푸시알림 발송 회수</h1>
							<bar-chart id="pushstate" :data="pushstate" xkey="date" ykeys='["count"]' bar-colors='["#36A2EB", "#FF6384"]' grid="true" grid-text-weight="bold" resize="true"></bar-chart>
						</div>
					</div>
				</div>

				<div class="col-lg-4">
					<div class="card">
						<div class="card-body">
							<h1 class="card-title">어플리케이션 접속 추이</h1>
							<line-chart id="applogin" :data="applogin" xkey="date" ykeys='["count"]' line-colors='["#36A2EB", "#FF6384"]' grid="true" grid-text-weight="bold" resize="true"></line-chart>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-lg-8">
					<div class="card">
						<div class="card-body">
							<div class="title">
								<h1 class="card-title">주요 상담내용</h1>
								<select v-model="detailYear" @change="loadContactCategory">
									<option value="2022">2022</option>
									<option value="2021">2021</option>
									<option value="2020">2020</option>
								</select>
								<select v-model="detailMonth" @change="loadContactCategory">
									<option value="1">1월</option>
									<option value="2">2월</option>
									<option value="3">3월</option>
									<option value="4">4월</option>
									<option value="5">5월</option>
									<option value="6">6월</option>
									<option value="7">7월</option>
									<option value="8">8월</option>
									<option value="9">9월</option>
									<option value="10">10월</option>
									<option value="11">11월</option>
									<option value="12">12월</option>
								</select>
							</div>
							<Bar chart-id="charstate" ref="chatcategory" :chart-data="chatstate" :chart-options="chartOptions"  :height="chatheight" />
						</div>
					</div>
				</div>

				<div class="col-lg-4">
					<div class="card">
						<div class="card-body">
							<h1 class="card-title">유형별 가입자</h1>
							<Pie chart-id="jointype" :chart-data="jointype" :chart-options="chartOptions" :width="joinwidth" :height="joinheight" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Bar,Pie } from 'vue-chartjs/legacy'
	import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'
	//import { momentProperties } from 'dist/assets/libs/moment/moment';

	ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

	export default {
		name: 'DashboardPage',
		data() {
			return {
				appjoin: [],
				pushstate: [],
				applogin: [],
				chatstate: {
					labels: [  ],
					datasets: []
				},
				chartOptions: {
					responsive: true,
				},
				chatheight: 183,
				joinwidth: 200,
				joinheight: 200,
				jointype: {
					labels: [  ],
					datasets: []
				},
				detailYear: 2022,
				detailMonth: 5,
			}
		},
		created() {
			const dt = new Date();
			this.detailYear = dt.getFullYear();
			this.detailMonth = dt.getMonth()+1;

			if(this.$cookies.isKey('auth') == false) {
				//this.$router.push('/login');
			}
		},
		mounted() {
			this.loadData();
		},
		methods: {
			loadContactCategory: function () {
				this.$http.get(`https://asai.sacesapp.com/v1/Chat/contactcategory?year=${this.detailYear}&month=${this.detailMonth}`)
				.then((res) => {
					let dataset = {
								labels: [],
								datasets: []
							};
					dataset.labels = [];
					dataset.datasets.push({
								label: 'Contact',
								data: [],
								backgroundColor: ['rgb(95, 118, 232)']
							});

					if(res.data.success) {
						res.data.list.forEach(x => {
							dataset.labels.push(x.keyword);
							dataset.datasets[0].data.push(x.count);
						});

						this.chatstate = {
							...dataset,
						}
					}
				})
			},
			loadData: function () {
				this.$http.get('https://asai.sacesapp.com/v1/Dashboard/login')
				.then((res) => {
					if(res.data.success) {
						this.applogin = res.data.list.map(x => {
							return {
								date: `${x.year}-${x.month}-${x.day}`,
								count: x.count,
								};
						});
						
					} else {
						this.applogin = [];
					}
				})

				this.$http.get('https://asai.sacesapp.com/v1/Dashboard/join')
				.then((res) => {
					if(res.data.success) {
						this.appjoin = res.data.list.map(x => {
							return {
								date: `${x.year}-${x.month}-${x.day}`,
								count: x.count,
								};
						});
						
					} else {
						this.applogin = [];
					}
				})

				this.$http.get('https://asai.sacesapp.com/v1/Dashboard/push')
				.then((res) => {
					if(res.data.success) {
						this.pushstate = res.data.list.map(x => {
							return {
								date: `${x.year}-${x.month}-${x.day}`,
								count: x.count,
								};
						});
					
					} else {
						this.applogin = [];
					}
				})

				this.loadContactCategory();

				this.$http.get('https://asai.sacesapp.com/v1/Dashboard/usertype')
				.then((res) => {
					this.jointype.labels = ['운영학교','국악','연극','영화','만화/애니메이션','무용','디자인','공예','사진'];
					this.jointype.datasets.push({
								label: '회원유형',
								data: [],
								backgroundColor: ['rgb(95, 118, 232)','rgb(34,202,128)','rgb(253,193,106)','rgb(255,79,112)','rgb(160,210,24)','rgb(216,33,132)','rgb(21,183,198)','rgb(211,87,29)','rgb(102,63,230)']
							});

					if(res.data.success) {
						this.jointype.datasets[0].data = [
							res.data.list[0].school,
							res.data.list[0].type1,
							res.data.list[0].type2,
							res.data.list[0].type3,
							res.data.list[0].type4,
							res.data.list[0].type5,
							res.data.list[0].type6,
							res.data.list[0].type7,
							res.data.list[0].type8
						];

						this.jointype = {
							...this.jointype,
						}

						console.log(this.jointype);
					}
				})
			}
		},
		components: {
			Bar, 
			Pie,
		}
	}
</script>

<style scoped>
	h1 {
		font-size: 18px;
		font-weight: 500;
		color: black;
	}

	.blank-box {
		height: calc(100vh - 620px);
	}

	.title {
		display: flex;
		justify-content: space-between;
		width: 250px;
	}

	.title>select {
		font-size: 18px;
		font-weight: 500;
		border: none;
		margin-top:-13px;
		color: #1a1e27;
	}

</style>