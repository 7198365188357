<template>
	<div id="faq-home">
		<div class="row">
			<div class="col-lg-8">
				<div class="card">
					<div class="card-body main-box">
						<category-menu :category="category" :menu="categoryMenu" @change="changeCategory" @new="writeNew"></category-menu>

						<table id="noticelist" class="table">
							<thead class="bg-primary text-white">
								<tr>
									<th>번호</th>
									<th>제목</th>
									<th>생성일</th>
								</tr>
							</thead>
							<tbody>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="card">
					<div class="card-body">
						<div class="iphone">
							<phone-notice-home :list="list" mode="faq"></phone-notice-home>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CategoryMenu from '../../../components/category'
	import PhoneNoticeHome from '../../../components/phonelist'

	export default {
		name: 'FaqPage',
		data() {
			return {
				category: '전체',
				categoryMenu: ['전체','예술강사','운영학교'],
				list: [],
				fields: ['배너여부'],
				categoryOn: false,
				curSeq: -1,
			}
		},
		mounted() {
			if(this.$route.params.category) {
				this.category = this.$route.params.category;
			}

			this.loadData();
		},
		methods: {
			loadData: function () {
				let param = {
					menuId: 'faq',
				};

				if(this.category != '전체') {
					param.category = this.category;
				}

				this.$http.post('https://asai.sacesapp.com/v1/Post', param)
				.then((res) => {
					if(res.data.success == true) {
						this.list = res.data.posts;
						if(window.$.fn.dataTable.isDataTable('#noticelist')) {
							const dt = window.$('#noticelist').dataTable();
							let idx = this.list.length;
							const data = this.list.map(function (x) {
									var r = {
										banner: x.banner != '' ? 'Y' : 'N',
										fixed: x.fixed != '' ? 'Y' : 'N',
										seq: idx--,
										subject: x.subject,
										filename: x.filename != '' ? 'Y' : 'N',
										createdAt: x.createdAt.substr(0,10)
									}

									return r;
								});

							dt.fnClearTable();

							if(data.length > 0) {
								dt.fnAddData(data);
							}
						} else {
							let idx = this.list.length;
							window.$('#noticelist').dataTable({
								data: this.list.map(function (x) {
									var r = {
										banner: x.banner != '' ? 'Y' : 'N',
										fixed: x.fixed != '' ? 'Y' : 'N',
										seq: idx--,
										subject: x.subject,
										filename: x.filename != '' ? 'Y' : 'N',
										createdAt: x.createdAt.substr(0,10)
									}

									return r;
								}),
								retrive: true,
								order: [[0,'DESC']],
								columns: [
									{data: 'seq'},
									{data: 'subject'},
									{data: 'createdAt'}
								]
							});

							const self = this;
							window.$('#noticelist').on('click', 'tbody tr', function () {
								var row = window.$('#noticelist').dataTable().api().row(this);
								self.$router.push({
									name: 'FaqView',
									params: {
										data: self.list[row[0][0]],
										category: self.category,
									}
								});
							})
						}
					}
				});
			},
			getDate: function (d) {
				return d.substr(0,10);
			},
			changeCategory: function (v) {
				this.category = v;
				this.loadData();
			},
			writeNew: function () {
				this.$router.push({
					name: 'FaqWrite',
					params: {
						mode: 'write',
						category: this.category
					}
				})
			}
		},
		components: {
			CategoryMenu,
			PhoneNoticeHome,
		}
	}
</script>

<style scoped>

.main-box {
	height: calc(100vh - 88px) !important;
}


.iphone {
	background-image:url('../../../assets/iphone.png');
	background-repeat: no-repeat;
	background-size: cover;
	height: 847px;
	width: 430px;
	padding: 70px 30px 50px 30px;
	margin:0 auto;
}
</style>