<template>
	<div id="faq-home">
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<category-menu :category="category" :menu="categoryMenu" mode="save" @change="changeCategory" @submit="onSubmit"></category-menu>

						<component :is="subComponent" :list="list" @change="onChange"></component>
					</div>
				</div>
			</div>
		</div>

		<div id="delete-confirm-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="danger-header-modalLabel" aria-hidden="false">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-danger">
										<h4 class="modal-title" id="danger-header-modalLabel">삭제하기</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
										<p>계속하시겠습니까?</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal">취소하기</button>
										<button type="button" class="btn btn-danger" @click="deleteUser">삭제하기</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->

		<!-- Primary Header Modal -->
		<div id="ok-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="primary-header-modalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-primary">
										<h4 class="modal-title" id="primary-header-modalLabel">알림
										</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
									<p>회원정보가 삭제되었습니다</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal">Close</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->
	</div>
</template>

<script>
	import CategoryMenu from '../../components/category'

	import Tutor from './tutor'
	import School from './school'
	import Admin from './admin'
	import General from './general'

	export default {
		name: 'UserPage',
		data() {
			return {
				subComponent: null,
				category: '예술강사',
				categoryMenu: ['예술강사','운영학교','운영기관','비회원'],
				list: [],
				fields: ['배너여부'],
				data: [],
			}
		},
		mounted() {
			this.subComponent = Tutor;
			this.changeCategory('예술강사');
		},
		methods: {
			changeCategory: function (v) {
				this.category = v;
				
				let url = '';
				switch(v) {
					case '예술강사' :
						url = 'https://asai.sacesapp.com/v1/User/admltut?pp=dfh83';
						this.subComponent = Tutor;
						break;
					case '운영학교' :
						url = 'https://asai.sacesapp.com/v1/User/admlsch?pp=dfh83';
						this.subComponent = School;
						break;
					case '운영기관' :
						url = 'https://asai.sacesapp.com/v1/User/admladm?pp=dfh83';
						this.subComponent = Admin;
						break;
					case '비회원' :
					url = 'https://asai.sacesapp.com/v1/User/admlgen?pp=dfh83';
						this.subComponent = General;
						break;
				}

				this.$http.post(url)
				.then((res) => {
					if(res.data.success) {
						this.list = res.data.users;
						console.log(this.list);
					}
				})
			},
			deleteUser: function () {
				let target = [];
				window.$('#delete-confirm-modal').modal('hide');

				this.data.filter(x => x.accept == 'D')
				.forEach(x => {
					target.push({
						seq: x.seq,
						accept: x.accept,
					})
				});

				console.log(this.target);
				this.$http.post('https://asai.sacesapp.com/v1/User/acptusr',target)
				.then((res) => {
					if(res.data.success) {
						window.$('#ok-modal').modal('show');
						this.changeCategory(this.category);
					}
				})
			},
			onChange: function (data) {
				this.data = data;
			},
			onSubmit: function () {
				window.$('#delete-confirm-modal').modal('show');
			}
		},
		components: {
			CategoryMenu,
			Tutor,
			School,
			Admin,
			General,
		}
	}
</script>

<style scoped>

</style>