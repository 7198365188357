<template>
	<div>
		<div class="file-link">
			<label>업로드 방법 : 학교선정관리 > 학교선정결과 엑셀 다운로드 후 업로드</label>
		</div>
		<table id="noticelist" class="table">
			<thead class="bg-primary text-white">
				<tr>
					<th>지역</th>
					<th>학교명</th>
					<th>학교코드</th>
					<th>주소</th>
					<th>연락처</th>
				</tr>
			</thead>
			<tbody>
			</tbody>
		</table>
	</div>
</template>

<script>
	export default {
		name: 'SchoolComponent',
		props: ['list'],
		watch: {
			list: function () {
				window.$.fn.dataTable.ext.errMode = 'none';
				if(window.$.fn.dataTable.isDataTable('#noticelist')) {
					const table = window.$('#noticelist').dataTable();
					const data = this.list.map((v) => {
						return {
						지역: v.학교선정결과,
						학교명: v.__EMPTY,
						학교코드: v.__EMPTY_1,
						연락처: v.__EMPTY_2,
						주소: v.__EMPTY_3,
					}});

					table.fnClearTable();

					if(data.length > 0) {
						table.fnAddData(data);
					}
				} else {
					const data = this.list.map((v) => {
						return {
						지역: v.학교선정결과,
						학교명: v.__EMPTY,
						학교코드: v.__EMPTY_1,
						연락처: v.__EMPTY_2,
						주소: v.__EMPTY_3,
					}});

					window.$('#noticelist').dataTable({
						data: data,
						retrive: true,
						columns: [
							{data:'지역'},
							{data:'학교명'},
							{data:'학교코드'},
							{data:'주소'},
							{data:'연락처'},
						]
					});
				}
			}
		}
	}
</script>

<style scoped>
.file-link {
	margin-bottom: 20px;
}

.file-link a {
	font-size: 16px;
	font-weight: 400px;
}

.file-link label {
	font-size: 16px;
	font-weight: 400;
	color: #1a1e27;
	margin-right: 30px;
}
#noticelist>thead>tr>th {
	font-size: 0.7vw;
}
</style>