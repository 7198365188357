<template>
	<div id="school-component">
	<div class="row">
			<div class="col-lg-4">
				<div class="card">
					<div class="card-body" style="min-height:310px;">
						<h2>기본정보</h2>

						<table class="default-table">
							<tr>
								<td>
									<label>학교명</label>
									<span>{{school.schoolName}}</span>
								</td>
							</tr>
							<tr>
								<td>
									<label>학교코드</label>
									<span>{{school.schoolCode}}</span>
								</td>
							</tr>
							<tr>
								<td>
									<label>담당자명</label>
									<span>{{school.teacher}}</span>
								</td>
							</tr>
							<tr>
								<td>
									<label>연락처</label>
									<span>{{school.phone}}</span>
								</td>
							</tr>
							<tr>
								<td>
									<label>이메일주소</label>
									<span>{{school.email}}</span>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>

			<div class="col-lg-8">
				<div class="card">
					<div class="card-body" style="min-height:310px;">
						<div class="title" style="width:270px;">
							<h2>연도별 배정정보</h2>
							<select v-model="summaryYear" @change="loadTutor">
								<option v-for="(item,index) in yearDummy" :key="index" :value="item">{{ item }}</option>
							</select>
						</div>

						<table style="margin-top:27px;width:210px">
							<tr style="font-size: 18px; font-weight:400; color: #8d9aa0;">
								<td>학교 배치시수</td>
								<td>완료시수</td>
							</tr>
							<tr style="font-size: 18px; font-weight:400; color: #303641;">
								<td>{{totalTime}}</td>
								<td>{{usedTime}}</td>
							</tr>
						</table>

						<table style="margin-top: 46px;width:100%;">
							<tr style="heihgt:32px;font-size: 18px; font-weight:400; color:#8d9aa0;">
								<td>분야</td>
								<td>교육과정</td>
								<td>배치시수</td>
								<td>배치상태</td>
								<td>강사명</td>
								<td>등록번호</td>
							</tr>
							<tr class="yearinfo" v-if="tutorList.length == 0">
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
							</tr>
							<tr class="yearinfo" v-for="(item,idx) in tutorList" :key="idx">
								<td>{{item.field}}</td>
								<td>{{item.curriculum}}</td>
								<td>{{item.tutorarrtime}}</td>
								<td>{{item.tutorarrstatus}}</td>
								<td>{{item.name}}</td>
								<td>{{item.tutorcode}}</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-lg-9">
				<div class="card">
					<div class="card-body" style="min-height:480px;">
						<div class="title">
							<h2>수업내역</h2>
							<select v-model="detailYear" @change="loadDetail">
								<option value="2022">2022</option>
								<option value="2021">2021</option>
								<option value="2020">2020</option>
							</select>
							<select v-model="detailMonth" @change="loadDetail">
								<option value="1">1월</option>
								<option value="2">2월</option>
								<option value="3">3월</option>
								<option value="4">4월</option>
								<option value="5">5월</option>
								<option value="6">6월</option>
								<option value="7">7월</option>
								<option value="8">8월</option>
								<option value="9">9월</option>
								<option value="10">10월</option>
								<option value="11">11월</option>
								<option value="12">12월</option>
							</select>

							<div class="summary">
								<label>완료시수</label>
								<span>{{monthFinishedHour}}</span>
							</div>
						</div>
							
						<table id="detaillist" class="table">
							<thead class="bg-primary text-white">
								<tr>
									<th>수업일</th>
									<th>요일</th>
									<th>교시</th>
									<th>시수</th>
									<th>교육과정</th>
									<th>수업주제</th>
									<th>강사명</th>
									<th>등록번호</th>
									<th>강사등록일</th>
									<th>담당교사확인일</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>-</td>
									<td>-</td>
									<td>-</td>
									<td>-</td>
									<td>-</td>
									<td>-</td>
									<td>-</td>
									<td>-</td>
									<td>-</td>
									<td>-</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="col-lg-3">
				<div class="card">
					<div class="card-body" style="min-height:480px;">
						<div>
							<div class="memo-title-box">
								<div style="display:flex">
									<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M3.46118 0.135742C1.73529 0.135742 0.336182 1.53485 0.336182 3.26074V15.0107C0.336182 16.7366 1.73529 18.1357 3.46118 18.1357H10.3006C11.1294 18.1357 11.9243 17.8065 12.5103 17.2204L17.4209 12.3099C18.0069 11.7238 18.3362 10.929 18.3362 10.1002V3.26074C18.3362 1.53485 16.9371 0.135742 15.2112 0.135742H3.46118ZM1.58618 3.26074C1.58618 2.22519 2.42563 1.38574 3.46118 1.38574H15.2112C16.2467 1.38574 17.0862 2.22519 17.0862 3.26074V10.1002C17.0862 10.112 17.0861 10.1239 17.0858 10.1357H13.4612C11.7353 10.1357 10.3362 11.5348 10.3362 13.2607V16.8854C10.3243 16.8856 10.3125 16.8857 10.3006 16.8857H3.46118C2.42563 16.8857 1.58618 16.0463 1.58618 15.0107V3.26074ZM11.5862 16.3757V13.2607C11.5862 12.2252 12.4256 11.3857 13.4612 11.3857H16.5761C16.5633 11.3993 16.5503 11.4128 16.537 11.426L11.6265 16.3365C11.6132 16.3498 11.5998 16.3628 11.5862 16.3757Z" fill="#212121"/>
									</svg>
									<span style="display:block;margin-left:10px;margin-top:-3px;color:black;">메모</span>
								</div>
							</div>

							<div class="memo-box">
								<textarea class="memo-text" v-model="memo">
								</textarea>
								<div class="memo-length">{{memoLength}}/1,000</div>
							</div>

							<button @click="clickMemoSave" style="float:right;margin-top: 15px;width:80px;" class="btn btn-primary">저장</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Primary Header Modal -->
		<div id="ok-modal" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="primary-header-modalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
								<div class="modal-header modal-colored-header bg-primary">
										<h4 class="modal-title" id="primary-header-modalLabel">알림
										</h4>
										<button type="button" class="close" data-dismiss="modal"
												aria-hidden="true">×</button>
								</div>
								<div class="modal-body">
									<p>메모가 저장되었습니다</p>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-light"
												data-dismiss="modal">Close</button>
								</div>
						</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
		</div><!-- /.modal -->
	</div>
</template>

<script>
	export default {
		name: 'SchoolComponent',
		props: ['data'],
		data() {
			return {
				code: '',
				monthFinishedHour: '-',
				tutorList: [],
				totalTime: '-',
				usedTime: '-',
				summaryYear: 2022,
				detailYear: 2022,
				detailMonth: 5,
				memo: '',
				yearDummy: [],
			}
		},
		mounted() {
			const dt = new Date();
			this.summaryYear = dt.getFullYear();
			this.detailYear = this.summaryYear;
			this.detailMonth = dt.getMonth()+1;

			for(let year = this.summaryYear; 2020 <= year; year--) {
				this.yearDummy.push(year);
			}

			/*window.$('#detaillist').dataTable({
				data: [],
				retrive: true,
				columns: [
					{data: 'lecturedate'},
					{data: ''},
					{data: 'lecturetime'},
					{data: 'finishedhour'},
					{data: 'curriculum'},
					{data: 'lecturetitle'},
					{data: 'tutorname'},
					{data: 'tutorcode'},
					{data: 'tutorcheckdate'},
					{data: 'teachercheckdate'}
				]
			});*/
		},
		watch: {
			data: function () {
				this.code = this.school.schoolCode;
				this.loadData();
			}
		},
		methods: {
			clickMemoSave: function () {
				this.memo = this.memo.trim();
				if(this.code == '' || this.memo == '') {
					return;
				}
				this.$http.post('https://asai.sacesapp.com/v1/Memo', {
					own: '운영기관',
					target: this.code,
					targetType: '운영학교',
					memo: this.memo,
				}).then((res) => {
					if(res.data.success) {
						window.$('#ok-modal').modal('show');
					}
				})
			},
			getDate: function (dt) {
				const date = new Date(dt);
				return date.getDate();
			},
			weekday: function (dt) {
				const week = ['일','월','화','수','목','금','토'];
				const date = new Date(dt);
				return week[date.getDay()];
			},
			commaInt: function (num) {
				var regexp = /\B(?=(\d{3})+(?!\d))/g;
				return num.toString().replace(regexp, ',');
			},
			loadMemo: function () {
				this.$http.post('https://asai.sacesapp.com/v1/Memo/get',{
					own: '운영기관',
					target: this.code,
				}).then((res) => {
					if(res.data.success) {
						this.memo = res.data.memo;
					}
				})
			},
			loadTutor: function () {
				this.$http.post('https://asai.sacesapp.com/v1/Mypage/Assign', {
					year: this.summaryYear.toString(),
					schoolCode: this.code,
				}).then((res) => {
					if(res.data.success === true) {
						this.totalTime = res.data.assign.assigntime;
						this.usedTime = res.data.assign.usedtime;
					}
				});

				this.$http.post('https://asai.sacesapp.com/v1/Mypage/TutorList',{
					year: this.summaryYear.toString(),
					schoolCode: this.code,
				}).then((res) => {
					if(res.data.success === true) {
						this.tutorList = res.data.list;
					}
				});
			},
			loadDetail: function () {
				this.$http.post('https://asai.sacesapp.com/v1/Mypage/Teach',{
					year: this.detailYear.toString(),
					schoolCode: this.code,
				}).then((res) => {
					if(res.data.success === true) {
						const payment = res.data.list.filter(x => {
									const dt = new Date(x.lecturedate);
									console.log(dt.getMonth()+1, this.detailMonth);
									return (dt.getMonth()+1) == this.detailMonth;
								}).map(x => {
									return {
										lecturedate: this.getDate(x.lecturedate),
										weekday: this.weekday(x.lecturedate),
										lecturetime: x.lecturetime,
										finishedhour: x.finishedhour,
										curriculum: x.curriculum,
										lecturetitle: x.lecturetitle,
										tutorname: x.tutorname,
										tutorcode: x.tutorcode,
										tutorcheckdate: x.tutorcheckdate,
										teachercheckdate: x.teachercheckdate
									}
								})
						
						if(payment.length > 0) {
							this.monthFinishedHour = 0;
							payment.forEach(x => {
								this.monthFinishedHour += parseInt(x.finishedhour);
							})
						} else {
							this.monthFinishedHour = '-';
						}

						if(window.$.fn.dataTable.isDataTable('#detaillist')) {
							const table = window.$('#detaillist').dataTable();
							const data = payment;

							table.fnClearTable();

							if(data.length > 0) {
								table.fnAddData(data);
							}
						} else {
							window.$('#detaillist').dataTable({
								data: payment,
								retrive: true,
								searching: false,
								style: '',
								columns: [
									{data: 'lecturedate'},
									{data: 'weekday'},
									{data: 'lecturetime'},
									{data: 'finishedhour'},
									{data: 'curriculum'},
									{data: 'lecturetitle'},
									{data: 'tutorname'},
									{data: 'tutorcode'},
									{data: 'tutorcheckdate'},
									{data: 'teachercheckdate'}
								]
							});
						}
					}
				});
			},
			loadData: function () {
				this.loadTutor();

				this.loadMemo();

				this.loadDetail();

				/*let paymonth = this.listYear.toString();
				if(this.month < 10) {
					paymonth += '0' + this.month.toString();
				} else {
					paymonth += this.month.toString();
				}*/

				
			},
		},
		computed: {
			school: function () {
				let school = {
					schoolName: '-',
					schoolCode: '-',
					teacher: '-',
					phone: '-',
					email: '-',
					classlevel: '-',
					memo: '-',
				}

				if(Object.keys(this.data).length > 0) {
					school = this.data;
				}

				return school;
			},
			memoLength: function () {
				const t = this.school;

				if(!t.memo) {
					return 0;
				}

				return t.memo.length;
			}
		}
	}
</script>

<style scoped>
	h2 {
		font-size: 24px;
		color: black;
		font-weight: 500;
	}

	.memo-title-box {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
		margin-top: 10px;
	}

	.memo-box {
		width: 300px;
		height: 323px;
		background-color: #e4e6e8;
		position: relative;
	}

	.memo-text {
		border: none;
		background-color: #e4e6e8;
		width: 100%;
		height: calc(100% - 50px);
		resize: none !important;
		padding: 20px;
	}

	.memo-length {
		position: absolute;
		bottom: 0;
		margin-bottom: -15px;
		right: 10px;
		height: 57px;
		text-align: center;
	}

	.memo-box>p {
		padding: 10px;
	}


	.title {
		display: flex;
		justify-content: space-between;
		width: 450px;
	}

	.title>select {
		font-size: 24px;
		font-weight: 500;
		border: none;
		margin-top:-6px;
		color: #1a1e27;
	}

	.summary {
		font-size: 24px;
	}

	.summary>label {
		color: #919fb6;
		margin-right: 15px;
		margin-bottom: 0;
		margin-top: -10px;
	}

	.yearinfo>td {
		color: black;
	}

	.summary>span {
		color: #1a1e27;
		margin-right: 42px;
	}

	.summary>span:last-child {
		margin-right:0;
	}

	.default-table {
		width: 100%;
	}

	.default-table>tr>td {
		display:flex;
		justify-content: space-between;
	}

	.default-table>tr>td>label {
		font-size: 18px;
		font-weight: 400;
		color: #919fb6;
	}

	.default-table>tr>td>span {
		font-size: 18px;
		font-weight: 400;
		color: black;
	}

	#detaillist {
		width: 100% !important;
		margin-top: 25px;
	}
</style>