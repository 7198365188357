<template>
	<div id="content-view-component">
		<div class="content-area">
			<h2>{{title}}</h2>
			<div class="top-title">
				<span>대상</span>
				<div style="width:124px;height:41px;background-color:#e9ecef;color:#4f5467;padding:8px 15px;">{{category == '' ? '공통' : category}}</div>
			</div>
			<div class="content-group">
				<p class="form-control">{{subject}}</p>
				<p class="form-control content-box" v-html="content"></p>
			</div>
			<div class="file-group">
				<ul>
					<li v-for="(file, idx) in files" :key="idx">
						<a :href="file.fileurl">{{file.filename}}</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ContentViewComponent',
		props: {
			title: {
				type: String,
				default: '',
			},
			subject: {
				type: String,
				default: '',
			},
			content: {
				type: String,
				default: '',
			},
			files: {
				type: Array,
				default: undefined,
			},
			category: {
				type: String,
				default: '',
			}
		},
		data() {
			return {

			}
		},
		mounted() {
			console.log(this.category);
		}
	}
</script>

<style scoped>
#content-view-component>.content-area>.top-title {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

#content-view-component>.content-area>.top-title>span {
	margin-top: 8px;
}

#content-view-component>.content-area>.top-title>select {
	width: 124px;
	height: 41px;
}

#content-view-component>.content-area>h2 {
	margin-top:12px;
	font-size: 24px;
}

#content-view-component>.content-area {
	margin-left: 27px;
}

#content-view-component>.content-area>p {
	margin-top: 16px;
	height: 383px;
}

#content-view-component .content-group {
	margin-top: 24px;
}
#content-view-component .content-box {
	height: calc(100vh - 392px);
	font-size: 16px;
	font-weight: 400;
	overflow: auto;
}
</style>